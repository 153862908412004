import axios, { AxiosError } from "axios";
const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "Content-type": "application/json"
  },
});
// todo: this is a temp fix until BFF lib can auto refresh expired tokens. bruteforces refresh by reloading entire SPA
 instance.interceptors.response.use(function (response) {
   // Any status code that lie within the range of 2xx cause this function to trigger
   return response;
 }, function (error:AxiosError) {
   // Any status codes that falls outside the range of 2xx cause this function to trigger
   if (error?.response?.status == 401 || error?.response?.status == 403) {
     //reload the page when unathorized or unauthenticated/forbidden
     location.reload();
     
   }
   return Promise.reject(error);
 });
export default instance