import { StrictMode, useEffect, useRef, useState } from 'react'
import { LayoutItem, useLayoutStore } from '../../../stores/layout'
import { GetItemBasedOnType, ItemProps } from '../ColumnItem'
import { Button, Form } from 'react-bootstrap'
import { selectOptions } from '../../../types/componentSettings'
import { mobileItemIndex } from './MobileNavMenu'
import { useComputed, useSignal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'

export type FlattenedItem = {
    columnId: string,
    item: LayoutItem,
    itemIndex: number,
    label: string | undefined
}
export const MobileMainContainer = () => {
    useSignals();
    const layoutStore = useLayoutStore();
    const flattenedItemArray = useSignal<FlattenedItem[]>([]);

    useEffect(() => {
        const updatedArray = layoutStore.columns.flatMap(column => column.items.map((item, itemIndex) => (
            { columnId: column.id, item, itemIndex, label: selectOptions.find(x => x.value == item.info.itemType)?.label }
        )));
        flattenedItemArray.value = updatedArray;
    }, [layoutStore.columns, selectOptions]);
    
    // for mobile view
    const currentItem = useComputed(() => {
        if (flattenedItemArray.value.length == 0) return null;

        const currentFlatItem = flattenedItemArray.value[mobileItemIndex.value];
        const currentItemProps: ItemProps = {
            columnId: currentFlatItem.columnId,
            index: currentFlatItem.itemIndex,
            itemsLength: 1,
            info: currentFlatItem.item.info,
            id: currentFlatItem.item.id
        };
        return GetItemBasedOnType(currentItemProps);
    });

    return (
        <div className="vw-100 d-flex flex-column overflow-x-hidden" style={{height: 'calc(100vh - 45px)'}}>
            {currentItem}
        </div>
    )
}