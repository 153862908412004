import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounceEffect, useDebounce, useDebounceState } from "@better-hooks/performance";
import { Button, Dropdown, DropdownButton, InputGroup, ListGroup, ToggleButton } from 'react-bootstrap'
import { ItemTemplate } from './ItemTemplate'
import { ItemProps } from '../layout/ColumnItem'
import { ItemTypes, NewsfeedSettings } from '../../types/componentSettings'
import { ReaderTypes, useReaderStore } from '../../stores/reader'

import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'

import NewsService, { INewsDTO, INewsShort, NewsFilterFlags }  from '../../services/newsService'
import SearchService, { ISearchNewsDTO, ISearchResultNewsList } from '../../services/searchService'
import { formValueToBit } from '../../helpers/bitwiseHelper'
import { toCapitalizedMonth, toLocalDateTimeStr } from '../../helpers/dateHelper'
import { useNotifyStore } from '../../stores/notify'
import { Search } from '../shared/Search';
import { DayPicker } from 'react-day-picker';
import { da } from 'date-fns/locale';
import { LoadAreaTrigger } from '../shared/LoadAreaTrigger';
import { useLayoutStore } from '../../stores/layout';
import { MyStocksEmptyText } from '../shared/MyStocksEmptyText';
import { isMobile } from 'react-device-detect';
import { signal, useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
export const NewNewsfeedItems = signal<Set<string>>(new Set<string>())

export function GenerateHeaderTitle(settings:NewsfeedSettings):string {
    const defaultTitle = 'Nyhedsfeed';
    if (isMobile) return defaultTitle;
    if (settings.title) return settings.title;
   
    const separator = ', ';
    let str = '';
    if (settings.marketwireNyheder) str += 'MarketWire' + separator
    if (settings.marketwireFlash) str += 'Flash' + separator
    if (settings.borsNasdaq) str += 'Nasdaq Copenhagen (CSE)' + separator
    if (settings.borsFirstNorth) str += 'First North' +  separator
    if (settings.borsSpotlight) str += 'Spotlight' + separator
    if (settings.borsAndre) str += 'Andre' + separator

    if (str.length > 0) {
        if (str.length > 67) {
            str = defaultTitle;
        }
        else {
            str = str.substring(0, str.length - separator.length)
        }
    }

    return str;
}

export interface NewsfeedQueryKey {
    searchText?: string,
    filterFlags: NewsFilterFlags,
    myStocks: boolean,
}

export const Newsfeed = (props:ItemProps) => {
    useSignals();
    const [selectedDay, setSelectedDay] = useState<Date>();
    const today = new Date();

    const bodyRef = useRef<HTMLDivElement>(null);
    const settings:NewsfeedSettings = props.info.itemSettings;
    const updateSettings = useLayoutStore(state => state.updateColumnItemSettings)
    const showReader = useReaderStore(state => state.show);
    const notifyCreate = useNotifyStore(state => state.create);
    const { ref: inViewRef, inView } = useInView({
        initialInView: false,
    })
    const {debounce, reset, active} = useDebounce({ delay: 300 })

    const filterFlags = formValueToBit(NewsFilterFlags, [
        settings.marketwireNyheder,
        settings.marketwireFlash,
        settings.borsNasdaq,
        settings.borsFirstNorth,
        settings.borsSpotlight,
        settings.borsAndre,
        // settings below not yet implemented
        settings.aktier,
        settings.makro,
        settings.avisuddrag,
        settings.tophistorier,
        
    ]);

    // dto based on newsfeed settings. fromDate property is added dynamically
    const baseDTO:INewsDTO = {
        companyIds: [],
        myStocks: settings.myStocks,
        filterFlags,
        limit: 20
    }
    const queryKey:NewsfeedQueryKey = {
        filterFlags,
        myStocks: baseDTO.myStocks
    }
    const query = useInfiniteQuery<INewsShort[], Error>({
        queryKey: ['newsList', queryKey], 
        queryFn: async ({pageParam = baseDTO}) => {
            try {
                return await NewsService.GetList(pageParam)
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        getNextPageParam: (_lastItem, allItems) => {
            if (_lastItem && _lastItem.length && _lastItem.length >= baseDTO.limit) {
                const lastRecord = _lastItem[_lastItem.length - 1];
                if (lastRecord.dateInserted) return { ...baseDTO, fromDate: lastRecord.dateInserted } 
            }
            return undefined;
        },
        retry: true,
        staleTime: Infinity, // prevent refetching old data
    });
    
    const flatData = useMemo<INewsShort[] | undefined>(() => {
        return query.data?.pages.flat();
    }, [query.data]);
    
    // search logic
    const [searchText, setSearchText] = useState<string>('');
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>('')
    const isSearching = searchText.length > 0;
    const querySearch = useInfiniteQuery<ISearchResultNewsList, Error>({
        queryKey: ['newsListSearch', { ...baseDTO, searchText: debouncedSearchText }], 
        queryFn: async ({pageParam = { ...baseDTO, searchText: debouncedSearchText }}) => {
            try {
                return await SearchService.GetNewsList(pageParam)
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        getNextPageParam: (_lastItem, allItems) => {
            if (_lastItem && _lastItem.data?.length && _lastItem.data.length >= baseDTO.limit) {
                const lastRecord = _lastItem.data[_lastItem.data.length - 1];
                if (lastRecord.dateInserted && _lastItem.hits !== _lastItem.data.length) {
                    return { ...baseDTO, fromDate: lastRecord.dateInserted, searchText: debouncedSearchText }
                } 
            }
            return undefined;
        },
        retry: true,
        staleTime: 5 * 60 * 1000,
        enabled: isSearching // only search if there is a search term
    });

    const flatSearchData = useMemo<INewsShort[] | undefined>(() => {
        return querySearch.data?.pages.map(x => x.data).flat();
    }, [querySearch.data]);

    const applySearchInput = (searchStr:string) => {
        debounce(() => {
            // debounced logic
            setDebouncedSearchText(searchStr);
            // scroll to top
            bodyRef.current?.scrollTo(0, 0);
        });
    }

    // debounce as it otherwise gets called way too many times
    useDebounceEffect(() => {
        if (inView) {
            if (isSearching) querySearch.fetchNextPage();
            else query.fetchNextPage();
        }
    }, { delay: 100 }, [query.data, querySearch.data, inView] )

    const flashFormats = [23, 81]
    const openReader = (item:INewsShort) => {
        if (flashFormats.includes(item.formatId)) {
            showReader(ReaderTypes.NEWSFEED_FLASH, item.id, debouncedSearchText);
        }
        else showReader(ReaderTypes.NEWSFEED, item.id, debouncedSearchText);
    }
    const colorFormatMap = new Map<number, string>([
        [23, 'bs-red'],
        [81, 'bs-red'],
        [71, 'bs-primary'],
        [84, 'bs-primary']
    ])

    const unsetItemNew = (item:INewsShort) => {
        NewNewsfeedItems.value.delete(item.id);
    }

    const toggleMyStocks = () => {
        const newSettings = {
            ...settings,
            myStocks: !settings.myStocks
        }
        updateSettings(props.columnId, props.index, newSettings);
        bodyRef.current?.scrollTo(0, 0);
    }

    const renderedData = (data:INewsShort[]|undefined) => {
        return data?.map((item, index) => {
            return (
                <li key={item.id + props.columnId + props.index} style={{cursor:'pointer'}}
                    onClick={() => openReader(item)}
                    className={`list-group-item list-group-item-action d-flex p-2 justify-content-between fs-7 ${NewNewsfeedItems.value.has(item.id) && 'bg-animate-insert'}`}
                    onAnimationEnd={() => unsetItemNew(item)}
                >
                    <span className="all-wrap" style={{color: `var(--${colorFormatMap.get(item.formatId)})`}}>
                        {item.finansHeadline}
                    </span>
                    <span className="text-muted ms-1" style={{minWidth: 'max-content'}}>
                        {toLocalDateTimeStr(item.dateInserted)}
                    </span>
                </li>
            )
        })
    }
    const noMyStocksAndEmptyList = settings.myStocks && flatData?.length == 0;
    const hasNextPage = searchText.length ? querySearch.hasNextPage : query.hasNextPage;
    return (
        <ItemTemplate { ...props } alwaysShowTitle={true} title={GenerateHeaderTitle(settings)} isLoading={query.isLoading || isSearching && querySearch.isLoading}>
            {/* Header */}
            <>
                <ToggleButton className="mx-1" style={{whiteSpace: 'nowrap'}}
                    onClick={toggleMyStocks}
                    variant={settings.myStocks ? 'primary rounded' : 'outline-secondary rounded'}
                    value="0"
                >
                    <i className="bi bi-person" />
                </ToggleButton>
                <Search searchText={searchText} setSearchText={setSearchText} onChange={applySearchInput} />
            </>
            {/* Body */}
            <div className="bg-white px-1 overflow-auto w-100 rounded-bottom" ref={bodyRef}>
                <ListGroup variant="flush" as="ol" className="h-100">
                {isSearching ?
                    renderedData(flatSearchData)
                    :
                    renderedData(flatData)
                }
                {
                    hasNextPage && !noMyStocksAndEmptyList ?
                    <div className="text-center text-muted">
                        <LoadAreaTrigger ref={inViewRef} />
                        Loading...
                    </div>
                    : null
                }
                {
                    noMyStocksAndEmptyList ? <MyStocksEmptyText />
                    : !hasNextPage ? <div className="text-center text-muted">Ikke flere resultater</div> 
                         : null
                }
                </ListGroup>
            </div>
        </ItemTemplate>
    )
}