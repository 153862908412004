import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounceEffect, useDebounce, useDebounceState } from "@better-hooks/performance";
import { Button, Dropdown, DropdownButton, InputGroup, ListGroup, OverlayTrigger, Popover, ToggleButton } from 'react-bootstrap'
import { ItemTemplate } from './ItemTemplate'
import { ItemProps } from '../layout/ColumnItem'
import { CalendarSettings, TempItemSettings } from '../../types/componentSettings'

import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'


import { calendarDateFormat, toCapitalizedMonth, toLocalDateTimeStr } from '../../helpers/dateHelper'
import { useNotifyStore } from '../../stores/notify'
import { DayPicker } from 'react-day-picker';
import { da } from 'date-fns/locale';
import { LoadAreaTrigger } from '../shared/LoadAreaTrigger';
import CalendarService, { CalendarType, ICalendarDTO, IFinanceCalendar, calendarNameMap, calendarTypeOptions, financialResultsCheckValue, pageSize } from '../../services/calendarService';
import { SubArrayLengthSum } from '../../helpers/miscHelper';
import { startOfToday } from 'date-fns';
import { CalendarEdit } from '../layout/editComponents/CalendarEdit';
import { useLayoutStore } from '../../stores/layout';
import { isMobile } from 'react-device-detect';
import { MyStocksEmptyText } from '../shared/MyStocksEmptyText';
export interface CalendarQueryKey {
    filter: CalendarType[],
    myStocks: boolean,
}
export const Calendar = (props:ItemProps) => {
    const layoutStore = useLayoutStore();
    const selectedDay = (props.info.itemSettings?.temp as TempItemSettings)?.date
    const setSelectedDay = (date:Date|undefined) => {
        layoutStore.tempSettingsChangeDate(props.columnId, props.index, date);
    }
    const updateSettings = layoutStore.updateColumnItemSettings;
    const [showDP, setShowDP] = useState<boolean>(false);

    const today = startOfToday()

    const bodyRef = useRef<HTMLDivElement>(null);
    const settings:CalendarSettings = props.info.itemSettings;

    const notifyCreate = useNotifyStore(state => state.create);
    const { ref: inViewRef, inView } = useInView({
        initialInView: false,
    })

    // dto based on newsfeed settings. fromDate property is added dynamically
    const baseDTO:ICalendarDTO = {
        filter: settings.filter,
        myStocks: settings.myStocks,
        fromDate: selectedDay ?? today,
        limit: 15,
        skip: 0,
    }
    const queryKey:CalendarQueryKey = {
        filter: settings.filter,
        myStocks: settings.myStocks
    }

    const query = useInfiniteQuery<IFinanceCalendar[], Error>({
        queryKey: ['calendarList', queryKey, selectedDay], 
        queryFn: async ({pageParam = baseDTO}) => {
            try {
                return await CalendarService.GetList(pageParam)
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        getNextPageParam: (_lastItem, allItems) => {
            if (_lastItem?.length == pageSize) {
                const skip = SubArrayLengthSum(allItems)
                return { ...baseDTO, skip } 
            }
            return undefined;
        },
        retry: true,
        staleTime: Infinity, // prevent refetching old data
    });
    
    const flatData = useMemo<IFinanceCalendar[] | undefined>(() => {
        return query.data?.pages.flatMap(x => x)
    }, [query.data]);
    

    // debounce as it otherwise gets called way too many times
    useDebounceEffect(() => {
        if (inView) {
            query.fetchNextPage();
        }
    }, { delay: 100 }, [query.data, inView] )

    const getTypeAffix = (calendarType:CalendarType) => {
        switch (calendarType) {
            case CalendarType.FinancialResultsQ1:
                return '(Q1)';
            case CalendarType.FinancialResultsQ2:
                return '(Q2)';
            case CalendarType.FinancialResultsQ3:
                return '(Q3)';
            case CalendarType.FinancialResultsQ4:
                return '(Q4)';
        
            default:
                return null;
        }
    }

    const toggleMyStocks = () => {
        const newSettings = {
            ...settings,
            myStocks: !settings.myStocks
        }
        updateSettings(props.columnId, props.index, newSettings);
        bodyRef.current?.scrollTo(0, 0);
    }


    const renderedData = (data:IFinanceCalendar[]|undefined) => {
        return data?.map((item, index) => {
            return (
                <li key={item.id + props.columnId + props.index}
                    className={`list-group-item p-2 fs-7`}
                >
                        <div className='float-end'>
                            <div className="ms-1 text-primary text-end text-wrap">
                                { item.marketwireInterview ? <i className="bi bi-mic-fill text-info me-2"></i> : null }
                                { item.marketwireCoverage ? <i className="bi bi-eye-fill text-info me-2"></i> : null }
                                { calendarNameMap.get(item.eventType) }
                            </div>
                            <div className="text-muted ms-1 text-end" style={{minWidth: 'max-content'}}>
                                {calendarDateFormat(new Date(item.eventDate))}
                            </div>
                        </div>
                        <div className="all-wrap">
                            {
                                item.eventType == CalendarType.MacroEconomy ? 
                                <>
                                    <div className="fw-bold">{item.subjectName}</div>
                                    <div>{item.companyName ?? item.text} {getTypeAffix(item.eventType)}</div>
                                    { item.companyName ? <div>{item.text}</div> : null }
                                </>
                                :
                                <>
                                    <div className="fw-bold">{item.companyName ?? item.text} {getTypeAffix(item.eventType)}</div>
                                    { item.companyName ? <div>{item.text}</div> : null }
                                </>
                            }
                            
                            { item.infoText ? <div className="fst-italic">Info: {item.infoText}</div> : null }
                            { item.marketwireInterviewText ? <div className="fst-italic">Marketwire interviewer: {item.marketwireInterviewText}</div> : null}
                            { item.marketwireCoverageText ? <div className="fst-italic">Marketwire dækker: {item.marketwireCoverageText}</div> : null}
                        </div>

                </li>
            )
        })
    }
    const noMyStocksAndEmptyList = settings.myStocks && flatData?.length == 0;

    const daypicker = (
        <Popover
            id={`calendar-popover-${props.id}`}
            style={{maxWidth: 400}}
            placement='auto'
        >
            <DayPicker
                id={`calendar-dp-${props.id}`}
                locale={da}
                className="mb-2 mt-0"
                mode="single"
                defaultMonth={selectedDay}
                selected={selectedDay}
                onSelect={(val) => {
                    setSelectedDay(val);
                    setShowDP(false);
                }}
                formatters={{
                    formatCaption: (date) => toCapitalizedMonth(date)
                }}
                footer={<div className="float-end">
                    <Button
                        size="sm"
                        className="mb-0 ms-2"
                        variant='secondary'
                        disabled={selectedDay === undefined}
                        onClick={() => {
                            setSelectedDay(undefined);
                            setShowDP(false);
                        }}
                    >
                        Nulstil
                    </Button>
                </div>}
                
            />
        </Popover>
    )

    return (
        <ItemTemplate { ...props } alwaysShowTitle={true} title={"Kalender"} isLoading={query.isLoading}>
            {/* Header */}
            <>
            <ToggleButton className="mx-1" style={{whiteSpace: 'nowrap'}}
                onClick={toggleMyStocks}
                variant={settings.myStocks ? 'primary rounded' : 'outline-secondary rounded'}
                value="0"
                >
                <i className="bi bi-person" />
            </ToggleButton>
            <OverlayTrigger
                trigger="click"
                key={`calendar-ot-${props.id}`}
                placement="bottom-end"
                rootClose={true}
                show={showDP}
                onToggle={(b) => setShowDP(b)}
                overlay={daypicker}
            >
                <Button
                    className="rounded me-1"
                    variant={selectedDay === undefined ? 'outline-secondary' : 'primary'}
                >
                    <i className="bi bi-calendar-date" />
                </Button>
              </OverlayTrigger>
              { !isMobile &&
                <Button 
                    size="sm"
                    className="mb-0 rounded"
                    variant='outline-secondary'
                    onClick={() => layoutStore.modalShow(props.columnId, props.index)}
                >
                      <i className="bi bi-gear" />
                </Button>
              }

            </>
            {/* Body */}
            <div className="bg-white px-1 overflow-auto w-100 rounded-bottom" ref={bodyRef}>
                <ListGroup variant="flush" as="ol" className="h-100">
                {
                    renderedData(flatData)
                }

                {
                    (query.hasNextPage && !noMyStocksAndEmptyList) ?
                    <div className="text-center text-muted">
                        <LoadAreaTrigger ref={inViewRef} />
                        Loading...
                    </div>
                    : null
                }
                {
                    noMyStocksAndEmptyList ? <MyStocksEmptyText /> 
                    : (!query.hasNextPage) ? <div className="text-center text-muted">Ikke flere resultater</div> 
                         : null
                }
                </ListGroup>
            </div>
        </ItemTemplate>
    )
}