export function GetAllEnumValues(enumType:any):any[] {
    const keys = Object.keys(enumType).filter(k => typeof enumType[k as any] === "number"); // ["A", "B"]
    const values = keys.map(k => enumType[k as any]); // [0, 1]
    return values;
}
export function SubArrayLengthSum<T>(arr:T[][]):number {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
        sum += arr[i].length;
    }
    return sum;
}

export function compareDesc (a:number|string, b:number|string) {
    if (a > b) return +1;
    if (a < b) return -1;
    return 0;
}
export function compareAsc (a:number|string, b:number|string) {
    if (a > b) return -1;
    if (a < b) return +1;
    return 0;
}