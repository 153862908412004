import { Form } from "react-bootstrap"
import { GenerateHeaderTitle } from "../../items/Newsfeed"

import { useLayoutStore } from "../../../stores/layout"
import { NewsfeedSettings } from "../../../types/componentSettings"
import { isMobile } from "react-device-detect"
import { useAuth } from "bff-auth-lib"
import { AuthHelper, AuthRole } from "../../../helpers/authHelper"
import { FormCheckPremium } from "../../shared/FormCheckPremium"

export const NewsfeedEdit = () => {

    const settings = useLayoutStore(state => state.modalItemSettings) as NewsfeedSettings
    const updateSettings = useLayoutStore(state => state.modalChangeSettingValue);

    const handleChangeValue = (el: React.ChangeEvent<HTMLInputElement>) => {
        const inputName = el.target.name;
        const inputValue = el.target.value;
        updateSettings(inputName, inputValue);
    }

    const handleChangeChecked = (el: React.ChangeEvent<HTMLInputElement>) => {
        const inputName = el.target.name;
        const inputChecked = el.target.checked;
        updateSettings(inputName, inputChecked);
    }

    return (
        <Form>
            <Form.Label>Indholdstype</Form.Label>
            <Form.Check id="marketwireNyheder" name="marketwireNyheder" type="checkbox" label="MarketWire" checked={settings.marketwireNyheder} onChange={handleChangeChecked}/>
            <FormCheckPremium id="marketwireFlash" name="marketwireFlash" type="checkbox" label="Flash" checked={settings.marketwireFlash} onChange={handleChangeChecked} roles={AuthRole.FLASH}/>
            <Form.Check id="borsNasdaq" name="borsNasdaq" type="checkbox" label="Nasdaq Copenhagen (CSE)" checked={settings.borsNasdaq} onChange={handleChangeChecked}/>
            <Form.Check id="borsFirstNorth" name="borsFirstNorth" type="checkbox" label="First North" checked={settings.borsFirstNorth} onChange={handleChangeChecked}/>
            <Form.Check id="borsSpotlight" name="borsSpotlight" type="checkbox" label="Spotlight" checked={settings.borsSpotlight} onChange={handleChangeChecked}/>
            <Form.Check id="borsAndre" name="borsAndre" type="checkbox" label="Andre" checked={settings.borsAndre} onChange={handleChangeChecked}/>
            { !isMobile ? <>
                <Form.Label className="mt-3">Navngiv læserude</Form.Label>
                <Form.Control name="title" type="text" placeholder={GenerateHeaderTitle(settings)} value={settings.title} onChange={handleChangeValue}/>
            </> : null}
            
        </Form>
    )
}