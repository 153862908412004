import { create } from 'zustand'
const localStorageKey = 'theme';
const options:SelectableTheme[] = ['auto', 'light', 'dark'];
const defaultOption = 'auto';

export type SelectableTheme = 'auto'|'light'|'dark';
export type DOMTheme = 'light'|'dark'

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setColorScheme);
window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', setColorScheme);

function setColorScheme(e:MediaQueryListEvent) {
    const state = useThemeStore.getState();
    if (state.selectedOption == 'auto') {
        state.setTheme(state.selectedOption);
    }
  }

function getThemeFromSelected(selected:string):DOMTheme {
    if (selected == 'auto') {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return 'dark';
        }
        else {
            return 'light'
        }
    }
    
    return (selected == 'light') ? 'light' : 'dark';
}

function setDOM(theme:SelectableTheme) {
    //Modifies the html root element
    document.documentElement.setAttribute('data-bs-theme', theme);
}

function initializeTheme ():{theme: DOMTheme, selectedOption: SelectableTheme} {
    const stored = localStorage.getItem(localStorageKey);
    let selected = (stored ?? defaultOption) as SelectableTheme;
    const actual = getThemeFromSelected(selected);
    setDOM(actual);
    return {
        theme: actual,
        selectedOption: selected
    }
}

export interface ThemeState {
    selectedOption: SelectableTheme,
    theme:DOMTheme,
    setTheme: (selectedOption:SelectableTheme) => void,
}
  
export const useThemeStore = create<ThemeState>()((set) => ({
    ...initializeTheme(),
    setTheme: (selectedOption: SelectableTheme) => set((state) => {
        if (options.includes(selectedOption)) {
            localStorage.setItem(localStorageKey, selectedOption);

            const theme = getThemeFromSelected(selectedOption);
            setDOM(theme);
            return {
                ...state,
                selectedOption,
                theme
            }
        }
        return {
          ...state,
        }
      }),
}))