import React, { useEffect, useRef, useState } from 'react'
import { Button, Overlay, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { useLayoutStore } from '../../stores/layout'
import { useDNDStore } from '../../stores/dragndrop'
import { ItemProps } from '../layout/ColumnItem'
import { isMobile } from 'react-device-detect'

type Props = ItemProps & {
    title:string,
    alwaysShowTitle: boolean,
    isLoading:boolean,
    children: JSX.Element[],
}

export const ItemTemplate = (props : Props) => {
    const layoutStore = useLayoutStore();
    const dndStore = useDNDStore();
    
    const headerElm = props.children[0];
    const bodyElm = props.children[1];
    const footerElm = props.children.length >= 3 ? props.children[2] : null;
    const [showDeleteConfirmPopover, setShowDeleteConfirmPopover]  = useState(false);

    const handleDelete = () => {
        setShowDeleteConfirmPopover(false);
        layoutStore.removeColumnItem(props.columnId, props.index)
    }


    const deleteConfirmPopover = (
        <Popover id="popover-basic">
          <div className="hstack gap-1 text-center">
            <div className="p-1 ps-2 hover-opacity" style={{width: 64, cursor: 'pointer'}} onClick={() => setShowDeleteConfirmPopover(false)}>
                Fortryd
            </div>
            <div className="vr"></div>
            <div className="p-1 pe-2 hover-opacity" style={{width: 64, color: '#ea868f', cursor: 'pointer'}} onClick={handleDelete}>
                Slet
            </div>
        </div>
        </Popover>
      );

    return (
        <div className="d-flex flex-column h-100 w-100 bg-white rounded overflow-x-hidden">
            <div className='d-flex justify-content-between w-100 align-items-center p-2 rounded-top'>
                { dndStore.unlocked || props.alwaysShowTitle ? 
                <>
                    <div style={{minWidth: 0}} className="me-1">
                        <h6 className="text-truncate mb-0" >{props.title}</h6>
                    </div>

                    <div>
                        <div className={`input-group ${isMobile ? '' : 'input-group-sm'} flex-nowrap align-items-center`}>
                            { dndStore.unlocked ?
                                <>
                                    <OverlayTrigger 
                                      trigger="click" 
                                      placement="bottom" 
                                      overlay={deleteConfirmPopover}
                                      rootClose={true}
                                      show={showDeleteConfirmPopover}
                                      onToggle={setShowDeleteConfirmPopover}
                                    >
                                        <Button className="rounded ms-auto mx-2" variant={showDeleteConfirmPopover ? 'danger' : 'outline-danger'} onClick={() => setShowDeleteConfirmPopover(!showDeleteConfirmPopover)}>
                                            <i className="bi bi-trash"></i>
                                        </Button>
                                    </OverlayTrigger>
                                    
                                    <Button className="rounded" variant='outline-dark' onClick={() => layoutStore.modalShow(props.columnId, props.index)}>
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                </>
                                : headerElm
                            }
                        </div>
                    </div>
                </> : headerElm }
            </div>
            {
                dndStore.unlocked ?
                <div className="h-100 d-flex justify-content-center align-content-center flex-wrap text-muted fst-italic rounded bg-white">
                    Tryk og træk for at flytte mig
                </div>
                
                : null
            }
                {/* // Loading overlay */}
                <div className="position-relative overflow-hidden h-100 w-100" style={{display: dndStore.unlocked ? 'none' : 'flex'}}>
                    { props.isLoading &&
                    <div className="position-absolute bg-white opacity-75 d-flex h-100"
                         style={{zIndex: 1, top: 0, bottom: 0, left: 0, right: 0}}>
                        <div className="spinner-border m-auto" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    // below, actual injected body
                    }
                    {bodyElm}
                </div>
            
            { dndStore.unlocked ? null : footerElm }
        </div>
    )
}