import { ICompany } from "../services/shareService"
const languages = navigator.language;
const currencyOptions = { style: 'currency', languages }

export function getSharePriceChangeClass (company:ICompany) {
    const share = company.shareprice;
    if (!share) return '';

    const bg = !company.animate ? 'bg-transparent' : (company.animate === 'increase') ? 'bg-animate-price-inc' : 'bg-animate-price-dec'
    let text = '';
    if (!share.changePct || share.changePct === 0.0) text = 'text-dark'
    else if (share.changePct > 0.0) text = 'text-success'
    else text = 'text-danger'
    return `${text} ${bg}`
}

export function getPriceChangeClass(price:number|undefined) {
    if (!price || price === 0.0) return 'text-dark'
    else if (price > 0.0) return 'text-success'
    else return 'text-danger'
}

export function parsePrice(value:number|undefined):string {
    if (!value) return '0';
    const res = Intl.NumberFormat(languages).format(value);
    return res;
}

export function getPercentChange(from:number, to:number) {
    const changePct = ((to - from) / from) * 100;
    return (changePct).toFixed(2).replace('.', ',');
}

export function getPercentChangeNum(from:number, to:number) {
    const changePct = ((to - from) / from) * 100
    return changePct;
}

export function getChange(from:number, to:number) {
    const change = to - from;
    return parsePrice(change);
}

export function shortenPrice(price:number|null|undefined) {
    if (!price) return;
    if (price >= 1000 || price <= -1000) return price.toFixed(0).replace('.', ',')
    if (price >= 100 || price <= -100) return price.toFixed(2).replace('.', ',')
    if (price >= 10 || price <= -10) return price.toFixed(3).replace('.', ',')
    return price.toFixed(4).replace('.', ',')
}

export function getGraphPriceChangeClass (changePct:number|undefined) {
    if (!changePct || changePct === 0.0) return 'text-dark bg-light'
    else if (changePct > 0.0) return 'text-white-2 bg-success'
    else return 'text-white-2 bg-danger'
}

// export function getSharePriceChangeClass (changePct:number|undefined) {
//     if (!changePct || changePct === 0.0) return 'text-dark bg-light'
//     else if (changePct > 0.0) return 'text-success bg-success-lite'
//     else return 'text-danger bg-danger-lite'
// }