import { Form } from "react-bootstrap"

import { useLayoutStore } from "../../../stores/layout"
import { CalendarSettings } from "../../../types/componentSettings"
import { CalendarType, CalendarTypeList, calendarTypeFinancialDenmarkArray, calendarTypeOptions, financialResultsCheckValue } from "../../../services/calendarService"
import { klona } from "klona"

export const CalendarEdit = () => {
    const settings = useLayoutStore(state => state.modalItemSettings) as CalendarSettings
    const updateSettingsValue = useLayoutStore(state => state.modalChangeSettingValue);
    const updateSettings = useLayoutStore(state => state.modalChangeSettings);

    const handleChangeValue = (el: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = el.target.value;
        updateSettingsValue("filter", inputValue);
    }

    const handleChangeChecked = (val:CalendarType) => {
        const newFilter = settings.filter.includes(val) ? settings.filter.filter(x => x !== val) : [...settings.filter, val]
        updateSettingsValue("filter", newFilter);
    }

    const handleChangeCheckedFinancial = () => {
        const newFilter = settings.filter.includes(financialResultsCheckValue) 
            ?  settings.filter.filter(x => !calendarTypeFinancialDenmarkArray.includes(x)) 
            : settings.filter.concat(calendarTypeFinancialDenmarkArray)
        updateSettingsValue("filter", newFilter);
    }

    const removeAll = () => {
        const settingsClone = klona(settings);
        settingsClone.filter = [];
        updateSettings(settingsClone);
    }
    const addAll = () => {
        const settingsClone = klona(settings);
        settingsClone.filter = [];
        for (const val of CalendarTypeList) {
            settingsClone.filter.push(val);
        }
        updateSettings(settingsClone);
    }

    return (
        <Form>
            <Form.Label>Indholdstype</Form.Label>
            <div className="d-flex text-muted px-2 justify-content-between mt-0 mb-2" style={{fontSize: 11, marginTop: 3}}>
                <span className="text-primary">
                    <u style={{cursor: 'pointer'}}
                        onClick={addAll}>
                        Vælg alle
                    </u>
                </span>
                <span className="text-primary">
                    <u style={{cursor: 'pointer'}}
                        onClick={removeAll}>
                        Fjern alle
                    </u>
                </span>
            </div>
            
            <Form.Check id="calendarOption-financialResults" name="financialResults" type="checkbox" label="Regnskaber - DK" checked={settings.filter.includes(financialResultsCheckValue)} onChange={handleChangeCheckedFinancial}/>
            {
                calendarTypeOptions.map((x, i) => 
                    <Form.Check key={i} id={`calenderOption-${x.label}`} name={x.label} type="checkbox" label={x.label} checked={settings.filter.includes(x.value)} onChange={() => handleChangeChecked(x.value)}/>
                )
            }
        </Form>
    )
}