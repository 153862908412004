export function enumToBitValues(enumValue: object) {
    return Object.keys(enumValue).map(Number).filter(Boolean);
}

export function formValueToBit(enumeration: object, selection: boolean[]) {
    const bits = enumToBitValues(enumeration);
    let acc = 0;
    for (let i = 0; i < selection.length; i++) {
        if (selection[i]) {
            acc += bits[i];
        }
    }
    return acc;
  }