import React, { useState } from "react";
import { Form, FormControl, FormControlProps, Spinner } from "react-bootstrap"
import { FormCheckInputProps } from "react-bootstrap/esm/FormCheckInput";
import { AuthHelper, AuthRole } from "../../helpers/authHelper";
import { useAuth } from "bff-auth-lib";
type Props = {
    isLoading?: boolean,
    text?:string,
    icon?:string,
    iconAlign?:'left'|'right',
    noborder?:boolean,
    rows?: number,
    label:string,
    roles: AuthRole|AuthRole[],
    disabled?: boolean,
}
export const FormCheckPremium = React.forwardRef((props: Props & FormCheckInputProps, ref:unknown) => {
    const { className, isLoading, text, icon, rows, label, disabled, roles, iconAlign = 'right', noborder = false,  ...otherProps} = props;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const builtInClass = `${isEditing ? '' : 'border-white bg-transparent'}`;
    let hasAccess:boolean = false;
    const auth = useAuth();
    if (Array.isArray(roles)) {
        hasAccess = AuthHelper.hasAnyRole(auth, roles);
    }
    else {
        hasAccess = AuthHelper.hasRole(auth, roles);
    }

    const isDisabled = disabled || !hasAccess;
    return (
        <Form.Check
            ref={ref} {...otherProps}
            label={
                <div>
                    <span style={{opacity: isDisabled ? 0.5 : 1}}>{label}</span>
                    {
                        isDisabled ? <span style={{fontSize: 11}} className="ms-1 text-primary align-top">Kræver Premium</span> : null
                    }
                </div>
            }
            rows={rows}
            onBlur={() => setIsEditing(false)}
            onFocus={() => setIsEditing(true)}
            className={`${builtInClass} premium-form-check ${className}`} 
            disabled={isDisabled}
        />
    )
});