import { Badge, CloseButton, Form, ListGroup } from "react-bootstrap"
import { GenerateHeaderTitle } from "../../items/ExternalFeeds"

import { useLayoutStore } from "../../../stores/layout"
import { ExternalFeedsSettings } from "../../../types/componentSettings"
import { ExternalFeeds, ExternalFeedMap } from "../../../services/externalFeedService"
import { klona } from "klona"
import { isMobile } from "react-device-detect"

export const ExternalFeedsEdit = () => {

    const settings = useLayoutStore(state => state.modalItemSettings) as ExternalFeedsSettings
    const updateSettings = useLayoutStore(state => state.modalChangeSettings);
    const updateSettingsValue = useLayoutStore(state => state.modalChangeSettingValue);
    const handleClickShare = (feed:ExternalFeeds) => {
        
        const settingsClone = klona(settings);
        if (settings.feeds.some(x => x === feed)) {
            // remove
            const filtered = settingsClone.feeds.filter(x => x !== feed);
            settingsClone.feeds = filtered;
        }
        else {
            settingsClone.feeds.push(feed);
        }
        updateSettings(settingsClone);
    }

    const removeAll = () => {
        const settingsClone = klona(settings);
        settingsClone.feeds = [];
        updateSettings(settingsClone);
    }
    const addAll = () => {
        const settingsClone = klona(settings);
        settingsClone.feeds = [];
        for (const [k, v] of ExternalFeedMap) {
            settingsClone.feeds.push(k);
        }
        updateSettings(settingsClone);
    }

    const handleChangeValue = (el: React.ChangeEvent<HTMLInputElement>) => {
        const inputName = el.target.name;
        const inputValue = el.target.value;
        updateSettingsValue(inputName, inputValue);
    }

    return (
        <Form>
            <div className="row d-flex mx-0 mb-1">
                {settings.feeds?.length ? settings.feeds.map((g, i) => 
                {
                    const feedInfo = ExternalFeedMap.get(g);
                    return (
                    <div key={i} className="col-auto px-0 mx-1 mb-1" >
                        <Badge pill bg="primary">
                            {feedInfo?.label} <i style={{cursor:'pointer'}} className="bi bi-x-lg" onClick={() => handleClickShare(g)} />
                        </Badge>
                    </div>
                    )
                }) : <div>Ingen medier valgt</div>}
            </div>
            <div className="d-flex text-muted px-2 justify-content-between mt-3 mb-2" style={{fontSize: 11, marginTop: 3}}>
                <span className="text-primary">
                    <u style={{cursor: 'pointer'}}
                        onClick={addAll}>
                        Vælg alle
                    </u>
                </span>
                <span className="text-primary">
                    <u style={{cursor: 'pointer'}}
                        onClick={removeAll}>
                        Fjern alle
                    </u>
                </span>
            </div>
            <div className="overflow-auto mt-2 border rounded" style={{minHeight: 100, height: 400}}>
                <ListGroup variant="flush">
                    {
                        Array.from(ExternalFeedMap).map(([k, v]) => (
                            <ListGroup.Item
                                key={k}
                                type="button"
                                action
                                active={settings.feeds.some(x => x === k)}
                                onClick={() => handleClickShare(k)}>
                                { v.label }
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </div>
            { !isMobile ? <>
                <Form.Label className="mt-3">Navngiv læserude</Form.Label>
                <Form.Control name="title" type="text" placeholder={GenerateHeaderTitle(settings)} value={settings.title} onChange={handleChangeValue}/>
            </> : null}
            
        </Form>
    )
}