import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { Bff } from 'bff-auth-lib/dist/lib/User'
import { AuthProvider, useAuth } from 'bff-auth-lib'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

//We need both pathname and query string in the return url.
var pathNameWithQuery = window.location.pathname + window.location.search;

const bffParams: Bff = {
  sitePathName: import.meta.env.VITE_BFF_BASE_PATH, //no sitepath for localhost as localhost:7156/bfftest/bff/user cannot find bff-endpoint.
  bffLoginEndpoint:`/bff/login?returnUrl=${pathNameWithQuery ? pathNameWithQuery : import.meta.env.VITE_RETURN_URL}`,
  bffUserEndpoint: '/bff/user',
  bffMeuEndpoint: null,
  telefon: '33 30 80 60',
  appName: 'Marketwire Terminal'
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: true,
    }
  }
});


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AuthProvider bff={bffParams}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>
);
