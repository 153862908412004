import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Badge, Card, CloseButton, ListGroup } from 'react-bootstrap';
import NewsService, { INewsShort } from '../../services/newsService';
import { toLocalDateTimeFullStr, toLocalDateTimeStr } from '../../helpers/dateHelper';
import { useNotifyStore } from '../../stores/notify';
import HighlightText from './HighlightText';

type Props = {
    newsId: string,
    searchStr:string,
    close: () => void,
}
export const NewsfeedFlashRW = (props:Props) => {
    const notifyCreate = useNotifyStore(state => state.create)
    const query = useQuery<INewsShort[]>({
        queryKey: ['newsFlashList', props.newsId],
        queryFn: async () => { 
            try {
                return await NewsService.GetFlashList(props.newsId) 
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
            
        },
        staleTime: 5 * 60_000,
    })
    const data = query.data;

    return (
        !data || query.isLoading ?
        <>
            <div className="mx-3 my-1">
                <CloseButton className="float-end" onClick={props.close} />
            </div>
            <div className="spinner-border m-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
        </>
        :
        <>
            <div className="mx-3 my-2">
                <small className="text-muted">
                    <span className="me-4">
                        {toLocalDateTimeFullStr(data[0].dateInserted)}
                    </span>
                    {/* <u className="me-4">
                        Print <i className="bi bi-printer-fill ms-1"></i>
                    </u>
                    <u className="me-4">
                        Del <i className="bi bi-send-fill ms-1"></i>
                    </u> */}
                </small>
                <CloseButton className="float-end" onClick={props.close}></CloseButton>
            </div>
            <div className="mx-3 my-2" >
                <h4 style={{color: 'var(--bs-red)'}}>{<HighlightText text={data[0].finansHeadline} searchString={props.searchStr} />}</h4>
            </div>
            {
                data.length > 1 ?
                <Card.Body className="border-0 text-muted pt-0 overflow-auto">
                    <div className="full-width">
                        <small><b>Relaterede flashnyheder</b></small>
                    </div>
                    {data.slice(1).map((item, index) => 
                        <div key={index}>
                            <div className="full-width text-muted pt-3">{toLocalDateTimeStr(item.dateInserted)}</div>
                            <h6 style={{color: 'var(--bs-red)'}}>{item.finansHeadline}</h6>
                        </div>
                    )}
                </Card.Body>
                : null
            }
            
        </>
        
  );
}
