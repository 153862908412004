import { useState } from 'react'
import { ColumnItem } from './ColumnItem'
import { LayoutColumn, useLayoutStore } from '../../stores/layout'
import { Droppable } from '@hello-pangea/dnd'
import { useDNDStore } from '../../stores/dragndrop'
import Button from 'react-bootstrap/Button';

type Props = LayoutColumn & {
}

export const ColumnContainer = (props: Props) => {
    const dndStore = useDNDStore(state => state);
    const modalShow = useLayoutStore(state => state.modalShow)

    function getColumnStyle(isDraggingOver:boolean) {
        return {
            background: isDraggingOver ? 'lightblue' : 'inherit',
            maxWidth: 550,
            minWidth: 367,
            width: 550,
            // overflowY: layout.unlocked ? "auto" as "auto" : "inherit" as "inherit"
        }
    }

    // return nothing if not in edit mode (unlocked) and column has no items.
    if (!dndStore.unlocked && !props.items.length) {
        return null;
    }
        return (
            <Droppable
                droppableId={props.id}
                // disable if more than 2 items unless item being dragged is inside the same column (meaning number of elements wont change)
                isDropDisabled={props.items.length > 2 && dndStore.dragSrcColId !== props.id}
                >
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="border-end border-gray d-flex flex-column overflow-auto"
                    style={getColumnStyle(snapshot.isDraggingOver)}
                >
                   {props.items.map((item, index) => {
                       return (
                        <ColumnItem
                            columnId={props.id}
                            key={item.id}
                            {...item }
                            itemsLength={props.items.length + (snapshot.isDraggingOver ? 1 : 0)}
                            index={index} />
                       )
                       
                    })}
                    { dndStore.unlocked && props.items.length < 3 && !snapshot.isDraggingOver &&
                        <div className="d-flex h-20 p-2">
                            <div className="d-flex bg-white rounded h-100 w-100 justify-content-center align-content-center flex-wrap">
                                <Button className="opacity-100" variant="primary" size="sm" onClick={() => modalShow(props.id)}>
                                    <div className="">Tilføj nyt komponent <i className="bi bi-plus-square"></i></div>
                                </Button>
                            </div>
                        </div>
                    }
                    {provided.placeholder}
                </div>
            )}
            </Droppable>
        )
  }