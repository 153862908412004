import { useAuth } from 'bff-auth-lib';
import { useEffect, useState } from 'react';
import { Button, CloseButton, ListGroup, Modal, Col, Nav, Row, Tab, Container, ListGroupItem, Form, OverlayTrigger, Tooltip, Popover, Badge} from 'react-bootstrap';
import LayoutService, { Layout, LayoutShareLevel, LayoutShareLevelOptions } from '../../services/layoutService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { b64_to_utf8, exportLayout, layoutVersion, useLayoutStore, utf8_to_b64 } from '../../stores/layout';
import { useNotifyStore } from '../../stores/notify';
import { useThemeStore } from '../../stores/theme';
import { isMobile } from 'react-device-detect';

type Props = {
    show: boolean,
}
export const LayoutContent = (props:Props) => {
    const notifyCreate = useNotifyStore(state => state.create);
    const layoutStore = useLayoutStore();
    const [layoutShareLevel, setLayoutShareLevel] = useState<LayoutShareLevel>(LayoutShareLevel.GLOBAL);
    const [editLayout, setEditLayout] = useState<Layout>();
    const queryClient = useQueryClient();

    const query = useQuery<Layout[]>({
        queryKey: ['layoutList', layoutShareLevel],
        queryFn: async () => await LayoutService.GetList(layoutShareLevel),
        staleTime: 5 * 60_000,
        enabled: props.show
    })

    const handleOnChangeShareLevel = (event:React.ChangeEvent<HTMLSelectElement>) => {
        const val = parseInt(event.target.value) as LayoutShareLevel;
        setLayoutShareLevel(val);
    }
    const handleOnChangeEditLayoutShareLevel = (event:React.ChangeEvent<HTMLSelectElement>) => {
        if (editLayout == null) return;
        const val = parseInt(event.target.value) as LayoutShareLevel;
        setEditLayout({...editLayout, shareLevel: val});
    }
    
    const handleChangeLayoutName = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (editLayout == null) return;
        const val = e.target.value;
        setEditLayout({...editLayout, name: val});
    }

    const handleClickEditCurrentLayout = () => {
        setEditLayout({
            name: '',
            version: layoutVersion,
            ownerOfLayout: true,
            data: exportLayout(),
            shareLevel: LayoutShareLevel.PERSONAL,
        })
    }

    const handleCloseLayoutEdit = () => {
        setEditLayout(undefined);
    }
    const handleSaveEditLayout = async () => {
        if (editLayout == null) return;

        try {
            const result = await LayoutService.Create(editLayout);
            if (!editLayout.id) {
                // if no id, its a new layout, which also means it has to be user's current layout
                // so we set the currently saved layout's Id to the created layouts Id
                layoutStore.importLayout(auth, result.data, result.id);
            }
            query.remove();
        } catch (error:any) {
            notifyCreate({
                message: error?.message,
                bg: 'danger',
            });
            throw error;
        }

        setEditLayout(undefined);
    }

    const handleDeleteLayout = async (layout:Layout) => {
        try {
            if (!layout.id) return false;
            const deleteSuccess = await LayoutService.Delete(layout.id);
            
            // delete
            queryClient.setQueriesData<Layout[]>(["layoutList"], (oldData:Layout[]|undefined) => {
                return produce(oldData, draft => {
                    if (!draft) return draft;
                    const removeIndex = draft.findIndex(x => x.id == layout.id);
                    draft.splice(removeIndex, 1);
                })
            })
            return deleteSuccess;
            
        } catch (error:any) {
            notifyCreate({
                message: error?.message,
                bg: 'danger',
            });
            return false;
        }
    }

    const handleImportLayout = (layout:Layout) => {
        try {
            layoutStore.importLayout(auth, layout.data, layout.id);
            notifyCreate({
                message: 'Layout blev importeret.',
                bg: 'success',
            });
        } catch (error:any) {
            notifyCreate({
                message: `Kunne ikke importere nyt layout - ${error?.message}`,
                bg: 'danger',
            });
        }
        
    }

    // for importing/exporting as base64 text
    const [showImportInput, setShowImportInput] = useState<boolean>(false);
    const toggleShowImportInput = () => setShowImportInput(!showImportInput);
    const [code, setCode] = useState<string>('')

    function handleExportLayout () {
        const layoutBase64 = exportLayout();
        navigator.clipboard.writeText(layoutBase64);
        notifyCreate({
            message: 'Nuværende layoutkode tilføjet til udklipsfolder',
            bg: 'info',
        });
    }

    function handleExportSpecificLayout (layout:Layout) {
        const layoutBase64 = layout.data;
        navigator.clipboard.writeText(layoutBase64);
        notifyCreate({
            message: 'Layoutkode tilføjet til udklipsfolder',
            bg: 'info',
        });
    }

    function handleImportLayoutFromText () {
        try {
            const layoutBase64 = code;
            layoutStore.importLayout(auth, layoutBase64);
            setShowImportInput(false);
            notifyCreate({
                message: 'Layout blev importeret.',
                bg: 'success',
            });
        } catch (error) {
            notifyCreate({
                message: 'Kunne ikke importere nyt layout - layoutkoden er ugyldig.',
                bg: 'danger',
            });
        }
    }

    const handleLayoutTextInputOnChange = (ev:React.ChangeEvent<HTMLInputElement>) => {
        setCode(ev.target.value);
    }

    useEffect(() => {
        setCode('');
    }, [showImportInput])
    const themeStore = useThemeStore();
    const auth = useAuth();
    const layoutEditorComponent = <>
    <h5>Skabeloner</h5>
        <div>
            {
                layoutStore.layoutId && query.data && query.data.find(x => x.id == layoutStore.layoutId)
                 || editLayout != null
                  ? null
                   : <Button size="sm" onClick={handleClickEditCurrentLayout} className="" variant="dark">Gem nuværende layout</Button>
            }
        </div>
        
        {
            editLayout != null ? <div className="my-3">
                <div className="d-flex justify-content-between mb-2">
                    <div className="text-muted fs-7 my-auto">Redigerer <b>{query.data?.find(x => x.id == editLayout.id)?.name}</b></div>
                    <Button onClick={handleCloseLayoutEdit} className="ms-2" size="sm" variant="dark">Luk</Button>
                </div>
                
                <div className="d-flex justify-content-between">
                    <Form.Control autoFocus placeholder="Layout navn" onChange={handleChangeLayoutName} type="text" value={editLayout.name}/>
                    {
                        auth.user?.roles.includes('terminal_admin') ?
                        <Form.Select
                            size="sm"
                            defaultValue={editLayout.shareLevel}
                            aria-label="Select share level"
                            style={{width: 105, borderColor: 'var(--bs-dark)', cursor: 'pointer'}}
                            className="ms-2 outline-dark"
                            onChange={handleOnChangeEditLayoutShareLevel}>
                            <option disabled>Deling</option>
                            { LayoutShareLevelOptions.map(x => <option key={x.value} value={x.value} label={x.label} />) }
                        </Form.Select>
                        : null
                    }

                    <Button disabled={!editLayout.name} onClick={handleSaveEditLayout} className="ms-2" size="sm" variant="success">Gem</Button>
                </div>
                
            </div> : null
        }
      
        <ListGroup className="mt-3">
            { query.data?.map(x => 
                <LayoutItemComponent
                 isOldVersion={layoutVersion != x.version} 
                 onDelete={handleDeleteLayout} 
                 importLayout={handleImportLayout} 
                 exportLayout={handleExportSpecificLayout} 
                 key={x.id} 
                 isCurrentLayout={!!(layoutStore?.layoutId && x.id == layoutStore.layoutId)} 
                 setLayoutItem={setEditLayout} 
                 active={editLayout?.id == x.id} 
                 {...x} />)
            }
        </ListGroup>
        
        <div className="mt-3">
            <div className='d-flex justify-content-between'>
                {
                    showImportInput ? 
                    <Button onClick={toggleShowImportInput} size="sm" variant="secondary">Luk importfelt</Button>
                    : <Button onClick={toggleShowImportInput} size="sm" variant="primary">Importer layout</Button>
                }
                
                {/* <Button onClick={handleExportLayout} size="sm" variant="outline-primary">Eksporter layout</Button> */}
            </div>
            {
                showImportInput ? 
                <div className="mt-2">
                    <Form.FloatingLabel label='Indsæt layoutkode fra udklipsholder' className='mb-1'>
                        <Form.Control autoFocus onChange={handleLayoutTextInputOnChange}></Form.Control>
                    </Form.FloatingLabel>
                </div>
                : null
            }
            
                
                <div className='mt-2 d-flex justify-content-between'>
                { showImportInput && code ?
                    <Button size='sm' variant='success' onClick={handleImportLayoutFromText}>Brug nyt layout</Button>
                    : <div></div>
                }
                 </div>
            
            
        </div>
    </>

    return <div className="h-100">
        <h5>Udseende</h5>
        <Form.Group className="d-flex mb-4">
            <Form.Check type='radio' className="me-4" id="dark-mode" label="Mørk 🌙" checked={themeStore.selectedOption == 'dark'} onChange={() => themeStore.setTheme('dark')} />
            <Form.Check type='radio' className="me-4" id="light-mode" label="Lys ☀️" checked={themeStore.selectedOption == 'light'} onChange={() => themeStore.setTheme('light')} />
            <Form.Check type='radio' id="auto-mode" label="Automatisk ⚙️" checked={themeStore.selectedOption == 'auto'} onChange={() => themeStore.setTheme('auto')} />
        </Form.Group>
        {
            !isMobile ? layoutEditorComponent: null
        }
    </div>
}



type LayoutItemProps = Layout & {
    active: boolean,
    isOldVersion: boolean,
    isCurrentLayout: boolean,
    setLayoutItem: (item:Layout) => void,
    importLayout: (layout:Layout) => void,
    exportLayout: (layout:Layout) => void,
    onDelete: (layout:Layout) => Promise<boolean>,
}
const LayoutItemComponent = (props:LayoutItemProps) => {
    const auth = useAuth();
    const [showDeleteConfirmPopover, setShowDeleteConfirmPopover] = useState<boolean>(false);
    const handleDelete = async () => {
        if (await props.onDelete(props)) {
            setShowDeleteConfirmPopover(false);
        }
    }
    const deleteConfirmPopover = (
        <Popover id="popover-basic">
          <div className="hstack gap-1 text-center">
            <div className="p-1 ps-2 hover-opacity" style={{width: 64, cursor: 'pointer'}} onClick={() => setShowDeleteConfirmPopover(false)}>
                Fortryd
            </div>
            <div className="vr"></div>
            <div className="p-1 pe-2 hover-opacity" style={{width: 64, color: '#ea868f', cursor: 'pointer'}} onClick={handleDelete}>
                Slet
            </div>
        </div>
        </Popover>
      );

    return <ListGroupItem variant={props.active ? 'secondary' : 'light'} className="d-flex justify-content-between text-dark border-secondary">
        <div className="d-flex flex-row align-items-center">
            <div className="ms-2">
                <div className="d-flex">
                    <h6 >{props.name}</h6>
                        { props.ownerOfLayout && auth.user?.roles.includes('terminal_admin') ?
                             <div className="fs-8 ms-1"><Badge bg="dark">Ejer</Badge></div>
                         : null 
                        }
                        { props.isCurrentLayout ? 
                            <div className="fs-8 ms-1"><Badge bg="primary">Valgt</Badge></div>
                         : null
                        }

                </div>
                
                <div className="d-flex flex-row">
                    <span className="fs-8">{LayoutShareLevelOptions.find(x => x.value == props.shareLevel)?.label}</span>
                </div>
            </div>
        </div>
        <div className="d-flex flex-row align-items-center">
            <div className="d-flex me-2">
                {
                    props.ownerOfLayout || auth.user?.roles.includes('terminal_admin') ? <>
                        <OverlayTrigger offset={[0, 10]} placement="top" overlay={<Tooltip id={`overlay-${props.id}`}>Rediger navn/deling</Tooltip>}>
                            <Button onClick={() => props.setLayoutItem(props)} className="me-2 border-0" size="sm" variant="outline-dark"><i className="bi bi-gear" /></Button>
                        </OverlayTrigger>
                        
                        <OverlayTrigger 
                            trigger="click" 
                            placement="bottom" 
                            overlay={deleteConfirmPopover}
                            rootClose={true}
                            show={showDeleteConfirmPopover}
                            onToggle={setShowDeleteConfirmPopover}
                        >
                            <Button size="sm" className="border-0 me-2" variant={showDeleteConfirmPopover ? 'danger' : 'outline-danger'} onClick={() => setShowDeleteConfirmPopover(!showDeleteConfirmPopover)}>
                                <i className="bi bi-trash"></i>
                            </Button>
                        </OverlayTrigger>
                        
                    </>
                        
                    : null
                }
                <OverlayTrigger offset={[0, 10]} placement="top" overlay={<Tooltip id={`overlay-export-${props.id}`}>Eksporter dette layout's kode.</Tooltip>}>
                    <Button className="border-0 me-2" size="sm" variant="outline-primary"><i className="bi bi-box-arrow-in-up" onClick={() => props.exportLayout(props)}/></Button>
                </OverlayTrigger>
                { props.isOldVersion ?
                    <OverlayTrigger offset={[0, 10]} placement="top" overlay={<Tooltip id={`overlay-${props.id}`}>Dette layout er fra en ældre version, og kan derfor ikke længere bruges.</Tooltip>}>
                        <Button className="border-0" size="sm" variant="outline-warning"><i className="bi bi-exclamation-triangle" /></Button>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger offset={[0, 10]} placement="top" overlay={<Tooltip id={`overlay-${props.id}`}>Vælg som layout</Tooltip>}>
                        <Form.Check type='radio' disabled={props.isCurrentLayout} checked={props.isCurrentLayout} onChange={() => props.importLayout(props)} className="mt-1 ms-1 me-2" />
                    </OverlayTrigger>
                }
            </div>
        </div>
        
    </ListGroupItem>
}