import { Accordion, AccordionButton, Badge, Button, Form, InputGroup, Row } from "react-bootstrap"
import MyCompaniesService, { MyCompanyDTO } from "../../services/myCompaniesService"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import ShareService, { ShareNames } from "../../services/shareService"
import { useForm } from "react-hook-form"
import { useNotifyStore } from "../../stores/notify"
import { produce } from "immer"
import { useRef, useState } from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import { isMobile } from "react-device-detect"
import { FormCheckPremium } from "../shared/FormCheckPremium"
import { AuthRole } from "../../helpers/authHelper"

type Props = {
    show: boolean,
    /** used to signal that MyCompanies has changed, 
     * and the components need to refresh their cache (Stockfeed & Calendar)
     */
    setReloadRequired: () => void;
}
export const MyCompaniesContent = (props:Props) => {
    const queryClient = useQueryClient();
    const notifyCreate = useNotifyStore(state => state.create);
    // for searching among all possible share companies in cache
    const companyListQuery = useQuery<ShareNames[]>({
        queryKey: ["companyList"],
        queryFn: async () => await ShareService.GetShareCompanies(),
        staleTime: 5 * 60_000,
        enabled: props.show,
    });
    // for listing users existing share companys (mine aktier)
    const myCompaniesQuery = useQuery<MyCompanyDTO[]>({
        queryKey: ["myCompanyList"],
        queryFn: async () => await MyCompaniesService.GetList(),
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: props.show,
    });

    const addNewCompany = async (company:ShareNames) => {
        if (!company) return;
        if (myCompaniesQuery.data && myCompaniesQuery.data.map(x => x.isin).includes(company.isin)) {
            notifyCreate({
                message: "Dette firma er allerede valgt",
                bg: 'warning',
            });
            return;
        }
        // add
        try {
            const result = await MyCompaniesService.Create(company.companyId);
            // cache
            queryClient.setQueriesData<MyCompanyDTO[]>(["myCompanyList"], (oldData:MyCompanyDTO[]|undefined) => {
                return produce(oldData, draft => {
                    if (!draft) {
                        draft = [result]
                    }
                    else {
                        draft.splice(0, 0, result)
                    }
                })
            })
            props.setReloadRequired();
        } catch (error:any) {
            notifyCreate({
                message: error?.message,
                bg: 'danger',
            });
        }
    }

    const removeMyCompany = async (id:number) => {
        // do API
        try {
            const res = await MyCompaniesService.Delete(id);
            if (res) {
                // delete
                queryClient.setQueriesData<MyCompanyDTO[]>(["myCompanyList"], (oldData:MyCompanyDTO[]|undefined) => {
                    return produce(oldData, draft => {
                        if (!draft) return draft;
                        const removeIndex = draft.findIndex(x => x.id == id);
                        draft.splice(removeIndex, 1);
                    })
                })
                props.setReloadRequired();
            }
        } catch (error:any) {
            notifyCreate({
                message: error?.message,
                bg: 'danger',
            });
        }
    }
    const saveMyCompany = async (dto:MyCompanyDTO):Promise<boolean> => {
        // do API
        try {
            const res = await MyCompaniesService.Update(dto);
            // delete
            queryClient.setQueriesData<MyCompanyDTO[]>(["myCompanyList"], (oldData:MyCompanyDTO[]|undefined) => {
                return produce(oldData, draft => {
                    if (!draft) return draft;
                    const replaceIndex = draft.findIndex(x => x.id == dto.id);
                    draft[replaceIndex] = dto;
                })
            })
            return true;
        } catch (error:any) {
            notifyCreate({
                message: error?.message,
                bg: 'danger',
            });
        }
        return false;
    }

    const typeaheadRef = useRef<Typeahead>(null);
    return <div style={{minHeight: isMobile ? 500 : 700}}>
        <h5>Mine aktier</h5>
        <div className="fs-7">Her kan du vælge aktier til din egen nyheds- og kursliste og sætte overvågning op, så du får en e-mail eller SMS, når der er nyt om dine aktier.</div>
        <form className="my-3" autoComplete="off">
            <input type="hidden" autoComplete="off" style={{display: 'none'}} />  {/** trick to prevent autocomplete on safari */}
            <Typeahead
                ref={typeaheadRef}
                id="company-search-input"
                labelKey='name'
                maxResults={999}
                minLength={0}
                paginate={false}
                size={isMobile ? 'lg' : 'sm'}
                multiple={false}
                placeholder="Søg og tilføj aktie"
                onChange={(selected:ShareNames[]) => {
                    addNewCompany(selected[0])
                    typeaheadRef.current?.clear();
                }}
                options={companyListQuery.data ?? []}
            />
            
        </form>

        <Accordion className="overflow-auto w-100 h-100">
            {myCompaniesQuery.data?.map((x, i) => <MyCompanyItem save={saveMyCompany} delete={removeMyCompany} {...x} key={x.id} />)}
        </Accordion>

    </div>
}

type MyCompanyItemProps = MyCompanyDTO & {
    delete: (companyId:number) => void,
    save: (dto:MyCompanyDTO) => Promise<boolean>,
}
const MyCompanyItem = (props:MyCompanyItemProps) => {
    const minChangeVal = 3;
    type FormValues = MyCompanyDTO & {
        mailKursChngStr: string,
        smsKursChngStr: string,
    }
    const form = useForm<FormValues>({
        mode: 'onBlur',
        defaultValues: {
            ...props,
            mailKursChngStr: `${props.mailKursChng}%`,
            smsKursChngStr: `${props.smsKursChng}%`,
        }
    });
    const mailToggled = form.watch('modtagMail')
    const smsToggled = form.watch('modtagSms')
    const { register, control, handleSubmit, formState, setValue, getValues, trigger, resetField, reset } = form;

    const decrement = (propertyName:'smsKursChngStr'|'mailKursChngStr') => {
        const propertyNameInt = propertyName == 'mailKursChngStr' ? 'mailKursChng' : 'smsKursChng';
        const newValue = getValues(propertyNameInt) === minChangeVal ? minChangeVal : getValues(propertyNameInt) - 1
        setValue(propertyNameInt, newValue);
        setValue(propertyName, newValue + '%')
    };
    const increment = (propertyName:'smsKursChngStr'|'mailKursChngStr') => {
        const propertyNameInt = propertyName == 'mailKursChngStr' ? 'mailKursChng' : 'smsKursChng';
        const newValue = getValues(propertyNameInt) + 1;
        setValue(propertyNameInt, newValue);
        setValue(propertyName, newValue + '%')
    };

    const onChangeMailKurs = (e:React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;
        let asNumber = (e.currentTarget.value.length == 0) ? minChangeVal : parseInt(inputValue.replace('%', ''));
        if (asNumber < minChangeVal) asNumber = minChangeVal;
        setValue('mailKursChng', asNumber);
        setValue('mailKursChngStr', `${asNumber}%`)
    }

    const onChangeSmsKurs = (e:React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;
        let asNumber = (e.currentTarget.value.length == 0) ? minChangeVal : parseInt(inputValue.replace('%', ''));
        if (asNumber < minChangeVal) asNumber = minChangeVal;
        setValue('smsKursChng', asNumber);
        setValue('smsKursChngStr', `${asNumber}%`)
    }

    const handleClickedSave = async (formValues:FormValues) => {
        const dto:MyCompanyDTO = {
            ...formValues,
        };
        const success = await props.save(dto);
        if (success) {
            reset(dto);
        }
    }

    return (
        <Accordion.Item eventKey={props.isin} className="border-secondary">
            <AccordionButton className="custom-accordion-button">
                <div>
                {props.companyName}
                </div>
                <div className="ms-auto fs-6">
                    {
                        props.modtagMail ? <Badge className="mx-1" bg="light" text="dark"><i className="bi bi-envelope-fill me-1 text-success" />{isMobile ? null : 'E-mail'}</Badge> : null
                    }
                    {
                        props.modtagSms ? <Badge className="mx-1" bg="light" text="dark"><i className="bi bi-bell-fill me-1 text-success" />{isMobile ? null : 'SMS'}</Badge> : null
                    }
                </div>
                
            </AccordionButton>
            <Accordion.Body>
              <Form as={Row} autoComplete="off" >
                <div className="col-12 col-lg-6">
                    <Form.Check
                      type="switch"
                      id="sms-toggle"
                      label="Overvåg på SMS"
                      {...register('modtagSms')}
                    />
                    <Form.Check id={`${props.id}-smsNyhed`} label="Nyheder fra MarketWire" {...register('smsNyhed', { disabled: !smsToggled})} />
                    <div className="d-flex">
                        <Form.Check id={`${props.id}-smsKurs`} label="Kursændring" {...register('smsKurs', { disabled: !smsToggled})} />
                        <InputGroup style={{width: 115}} className="ms-3">
                            <Button variant="light" size="sm" onClick={() => decrement('smsKursChngStr')}><i className="bi bi-chevron-down"/></Button>
                            <Form.Control min={1} type="text" maxLength={3} size="sm" id={`${props.id}-smsKursChngStr`} 
                                {...register('smsKursChngStr', {
                                    onChange: onChangeSmsKurs,
                                    pattern: {
                                        value: /^(100|[1-9]?[0-9])%?$/, // Regular expression to accept numbers from 0 to 100 with optional '%' symbol
                                        message: 'Please enter a valid percentage (0-100)',
                                    },
                                })}
                            />
                            <Button variant="light" size="sm" onClick={() => increment('smsKursChngStr')}><i className="bi bi-chevron-up"/></Button>
                        </InputGroup>
                    </div>
                    <Form.Check id={`${props.id}-smsSelskab`} label="Selskabsmeddelelse" {...register('smsSelskab', { disabled: !smsToggled})} />
                    <FormCheckPremium id={`${props.id}-smsFlash`} label="Flash" {...register('smsFlash', { disabled: !smsToggled})} roles={AuthRole.FLASH}/>
                </div>
                <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                    <Form.Check
                      type="switch"
                      id="mail-toggle"
                      label="Overvåg på e-mail"
                      {...register('modtagMail')}
                    />
                    <Form.Check id={`${props.id}-mailNyhed`} label="Nyheder fra MarketWire" {...register('mailNyhed', { disabled: !mailToggled})} />
                    <div className="d-flex">
                        <Form.Check id={`${props.id}-mailKurs`} label="Kursændring" {...register('mailKurs', { disabled: !mailToggled})} />
                        <InputGroup style={{width: 115}} className="ms-3">
                            <Button variant="light" size="sm" onClick={() => decrement('mailKursChngStr')}><i className="bi bi-chevron-down"/></Button>
                            <Form.Control min={1} type="text" maxLength={3} size="sm" id={`${props.id}-mailKursChngStr`} 
                                {...register('mailKursChngStr', {
                                    onChange: onChangeMailKurs,
                                    pattern: {
                                        value: /^(100|[1-9]?[0-9])%?$/, // Regular expression to accept numbers from 0 to 100 with optional '%' symbol
                                        message: 'Please enter a valid percentage (0-100)',
                                  },
                                })}
                            />
                            <Button variant="light" size="sm" onClick={() => increment('mailKursChngStr')}><i className="bi bi-chevron-up"/></Button>
                        </InputGroup>
                    </div>
                    <Form.Check id={`${props.id}-mailSelskab`}label="Selskabsmeddelelse" {...register('mailSelskab', { disabled: !mailToggled})} />

                    <FormCheckPremium id={`${props.id}-mailFlash`} label="Flash" {...register('mailFlash', { disabled: !mailToggled})} roles={AuthRole.FLASH} />
                </div>
                <div className="mt-3 d-flex justify-content-between">
                    <Button size="sm" variant="danger" onClick={() => props.delete(props.id)}>Slet</Button>
                    {
                        formState.isDirty ? <Button size="sm" variant="success" onClick={handleSubmit(handleClickedSave)}>Gem</Button> : null
                    }
                    
                </div>
              </Form>
            </Accordion.Body>
        </Accordion.Item>
    )
}