import React, { useEffect, useRef } from 'react';

interface Props {
  text: string;
  searchString: string;
}

const HighlightText = ({text = '', searchString = ''}:Props) => {
    if (!searchString.trim()) {
      return <span>{text}</span>
    }
    const searchStrings = searchString.split(' ').filter(x => x.trim() !== "");
    const regex = new RegExp(`(${searchStrings.join('|')})`, 'gi')
    const parts = text.split(regex);

    return (
      <span>
         {parts.filter(part => part).map((part, i) => (
             regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
         ))}
     </span>
    )
 }

export default HighlightText;