import { create } from 'zustand'
import { v4 as uuid } from 'uuid';

export interface NewNotification {
    bg?: string,
    message: string,
    header?: string,
    duration?: number
}

interface Notification extends NewNotification {
  id: string,
}

export interface NotifyState {
  notifications: Notification[],
  create: (newNotification: NewNotification) => void,
  remove: (id: string) => void,
}

export const useNotifyStore = create<NotifyState>()((set) => ({
    notifications: [],
    create: (newNotification: NewNotification) => set((state) => ({
      // ...state,
      notifications: [
        ...state.notifications,
        {
          ... newNotification,
          id: uuid()
        }
      ]
    })),
    remove: (id: string) => set((state) => ({
      // ...state,
      notifications: state.notifications.filter(x => x.id != id)
    })),
}))