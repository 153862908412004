import { useState } from 'react'
import { CloseButton, Toast, ToastContainer } from 'react-bootstrap';

export type SortState = 'off'|'asc'|'desc';

type Props = {
    state: SortState,
    setState: (newState:SortState) => void,
    label?: string,
}

const getNextState = (state:SortState) => {
    if (state == 'off' || state == 'desc') return 'asc';
    return 'desc';
}
const getIcon = (state:SortState) => {
    if (state == 'asc') return 'bi-caret-up-fill';
    if (state == 'desc') return 'bi-caret-down-fill';
    else return 'bi-caret-down-fill';
}

export const SortButton = (props:Props) => {
    return (
        <span className="sort-button" onClick={() => props.setState(getNextState(props.state))}>
            {props.label} <span className={props.state == 'off' ? 'sb-disabled' : 'sb-enabled'}>
                <i className={`bi ${getIcon(props.state)}`} />
            </span>
        </span>
        
    )
}

