import apiClient from "../http-common";

export enum LayoutShareLevel {
    PERSONAL = 1,
    COMPANY,
    GLOBAL
}

export const LayoutShareLevelOptions = [
    { value: LayoutShareLevel.PERSONAL, label: 'Personlig'},
    { value: LayoutShareLevel.COMPANY, label: 'Firma'},
    { value: LayoutShareLevel.GLOBAL, label: 'Marketwire skabelon'}
];

export interface Layout {
    id?: number,
    /** layout version - older versions are incompatible and should be deleted or marked for deletion and unusable in UI */
    version: number,
    /** layout json data as base64 string */
    data: string,
    name: string,
    /** who to share this layout with */
    shareLevel: LayoutShareLevel,
    /** determines whether current user has delete perms to layout */
    ownerOfLayout: boolean,
}

/** Service for fetching data from the Layout controller. */
export default class LayoutService {
    static readonly controller = '/layout';

    static async GetList(shareLevel:LayoutShareLevel) {
        const response = await apiClient.get<Layout[]>(`${this.controller}?shareLevel=${shareLevel}`);
        return response.data;
    }

    static async Delete(id:number) {
        const response = await apiClient.delete<boolean>(`${this.controller}?id=${id}`);
        return response.data;
    }

    static async Create(layout:Layout) {
        const response = await apiClient.post<Layout>(this.controller, { ...layout });
        return response.data;
    }
}