import { Form, ListGroup } from "react-bootstrap"
import { useLayoutStore } from "../../../stores/layout"
import { GraphSettings } from "../../../types/componentSettings"
import ShareService, { ShareNames } from "../../../services/shareService"
import { useQuery } from "@tanstack/react-query"
import { useNotifyStore } from "../../../stores/notify"
import { klona } from "klona"
import { graphColors } from "../../../stores/stockwindow"
import { Search } from "../../shared/Search"
import { useState } from "react"

export const GraphEdit = () => {
    const notifyCreate = useNotifyStore(state => state.create)
    const settings = useLayoutStore(state => state.modalItemSettings) as GraphSettings
    const updateSettings = useLayoutStore(state => state.modalChangeSettings);

    const query = useQuery<ShareNames[]>({
        queryKey: ['shareCompanies'],
        queryFn: async () => { 
            try {
                return await ShareService.GetShareCompanies()
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        staleTime: 5 * 60_000,
    })
    const data = query.data;

    const handleClickShare = (share: { isin:string, name:string }) => {
        
        const settingsClone = klona(settings);
        if (settings.graphs.some(x => x.isin === share.isin)) {
            // remove
            const filtered = settingsClone.graphs.filter(x => x.isin !== share.isin);
            settingsClone.graphs = filtered;
            
        }
        else {
            if (settings.graphs.length >= 2) {
                notifyCreate({
                    message: 'Du kan højst vise 2 grafer.',
                    bg: 'info',
                })
                return;
            }
            // add
            const update = { name: share.name, isin: share.isin }
            settingsClone.graphs.push(update);
        }
        updateSettings(settingsClone);
        
    }
    
    
    const [searchText, setSearchText] = useState('')
    const searchTextUpper = searchText.toLocaleUpperCase()
    const filtered = searchText ? data?.filter(x => x.name.toLocaleUpperCase().includes(searchTextUpper)) : data?.slice();

    return (
        <Form>
            <div className="row d-flex">
                {settings.graphs?.length ? settings.graphs.map((g, i) => (
                    <div key={i} className="col-auto" >
                        <span>
                            <i className="bi bi-circle-fill mx-2" style={{color: graphColors[i]}}></i>
                        </span>
                        <span>
                            {g.name}
                        </span>
                        <span onClick={() => handleClickShare(g)} style={{cursor: 'pointer'}}>
                            <i className="bi bi-x-lg mx-2"></i>
                        </span>
                    </div>
                )) : <div>Ingen aktier valgt</div>}
            </div>
            <div className="mt-2 mb-1">
                <Search fill={true} searchText={searchText} setSearchText={setSearchText}/>
            </div>
            <div className="overflow-auto border rounded mt-2" style={{minHeight: 100, height: 400}}>
                <ListGroup variant="flush">
                    {
                        filtered?.length ? filtered.map((d, i) => (
                            <ListGroup.Item
                                key={i}
                                type="button"
                                action
                                active={settings.graphs?.some(x => d.isin === x.isin)}
                                onClick={() => handleClickShare(d)}>
                                { d.name }
                            </ListGroup.Item>
                        )) : null
                    }
                </ListGroup>
            </div>
        </Form>
    )
}