import apiClient from "../http-common";
export enum ExternalFeeds {
    // WATCH_MEDIER,
    SHIPPING_WATCH = 1,
    POLICY_WATCH,
    MOBILITY_WATCH,
    MED_WATCH,
    MEDIA_WATCH,
    K_FORUM,
    KAP_WATCH,
    IT_WATCH,
    FODEVARE_WATCH,
    FINANS_WATCH,
    ENERGI_WATCH,
    EJENDOMS_WATCH,
    DETAIL_WATCH,
    CT_WATCH,
    AGRI_WATCH,
    ADVOKAT_WATCH,

    FINANS_DK
}

export const ExternalFeedMap = new Map([
    // path unused atm
    [ ExternalFeeds.ADVOKAT_WATCH,  { label: 'AdvokatWatch'   , path: '/advokatwatch.dk/latest' }    ],
    [ ExternalFeeds.AGRI_WATCH,     { label: 'AgriWatch',       path: '/agriwatch.dk/latest' }    ],
    [ ExternalFeeds.CT_WATCH,       { label: 'CleantechWatch' , path: '/ctwatch.dk/latest' }    ],
    [ ExternalFeeds.DETAIL_WATCH,   { label: 'DetailWatch'    , path: '/detailwatch.dk/latest' }    ],
    [ ExternalFeeds.EJENDOMS_WATCH, { label: 'EjendomsWatch'  , path: '/ejendomswatch.dk/latest' }    ],
    [ ExternalFeeds.ENERGI_WATCH,   { label: 'EnergiWatch'    , path: '/energiwatch.dk/latest' }    ],
    [ ExternalFeeds.FINANS_WATCH,   { label: 'FinansWatch'    , path: '/finanswatch.dk/latest' }    ],
    [ ExternalFeeds.FINANS_DK,      { label: 'Finans.dk'      , path: '' }    ],
    [ ExternalFeeds.FODEVARE_WATCH, { label: 'FødevareWatch'  , path: '/fodevarewatch.dk/latest' }    ],
    [ ExternalFeeds.IT_WATCH,       { label: 'ITWatch'        , path: '/itwatch.dk/latest' }    ],
    [ ExternalFeeds.KAP_WATCH,      { label: 'KapitalWatch'   , path: '/kapwatch.dk/latest' }    ],
    [ ExternalFeeds.K_FORUM,        { label: 'KForum'         , path: '/kforum.dk/latest' }    ],
    [ ExternalFeeds.MED_WATCH,      { label: 'MedWatch'       , path: '/medwatch.dk/latest' }    ],
    [ ExternalFeeds.MEDIA_WATCH,    { label: 'MediaWatch'     , path: '/mediawatch.dk/latest' }    ],
    [ ExternalFeeds.MOBILITY_WATCH, { label: 'MobilityWatch'  , path: '/mobilitywatch.dk/latest' }    ],
    [ ExternalFeeds.POLICY_WATCH,   { label: 'PolicyWatch'    , path: '/policywatch.dk/latest' }    ],
    [ ExternalFeeds.SHIPPING_WATCH, { label: 'ShippingWatch'  , path: '/shippingwatch.dk/latest' }    ],
])

export interface IExternalFeedsDTO {
    skip: number,
    limit: number,
    feeds: ExternalFeeds[],
    searchText?:string
}

export interface ExternalFeedNews {
    id: string;
    url: string;
    title: string;
    description: string;
    author: string | null;
    datePublished: string;
    dateAdded: string;
    feedEnum: ExternalFeeds;
    category: string;
    contentUrl: string | null;
}
/** Service for fetching data from the WatchMedier controller. */
export default class ExternalFeedService {
    static readonly controller = '/externalFeeds';

    static async GetList(dto:IExternalFeedsDTO) {
        const response = await apiClient.post<ExternalFeedNews[]>(this.controller + '/getList', dto);
        return response.data;
    }
}