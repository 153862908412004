import { SortState } from "../components/shared/SortButton";
import { GetAllEnumValues } from "../helpers/miscHelper";
import { CalendarType } from "../services/calendarService";
import { GraphRange, MarketIndex } from "../services/shareService";
import { ExternalFeeds } from "../services/externalFeedService";

export enum ItemTypes {
    NEWSFEED,
    GRAPH,
    STOCKFEED,
    EXTERNALFEEDS,
    CALENDAR,
}

export const selectOptions: {label: string, value: ItemTypes}[] = [
    { label: 'Nyhedsfeed', value: ItemTypes.NEWSFEED },
    { label: 'Kursgraf', value: ItemTypes.GRAPH },
    { label: 'Aktiefeed', value: ItemTypes.STOCKFEED },
    { label: 'Andre Medier', value: ItemTypes.EXTERNALFEEDS },
    { label: 'Kalender', value: ItemTypes.CALENDAR }
]

/** part of item settings which should not persist through reloads */
export interface TempItemSettings {
    date?: Date|undefined,
}
export class ItemSettings {
    temp?: TempItemSettings|undefined;
    showSettingsButton: boolean;
    constructor () {
        this.showSettingsButton = true;
    }
}

export class CalendarSettings extends ItemSettings {
    title: string;
    filter: CalendarType[];
    myStocks: boolean;
    constructor() {
        super();
        this.title = '';
        this.filter = [];
        this.myStocks = false;
    }
}

export class NewsfeedSettings extends ItemSettings {
    title: string;

    marketwireNyheder: boolean;
    marketwireFlash: boolean;
    borsNasdaq: boolean;
    borsFirstNorth: boolean;
    borsSpotlight: boolean;
    borsAndre: boolean;
    aktier: boolean;
    makro: boolean;
    avisuddrag: boolean;
    tophistorier: boolean;
    
    myStocks: boolean;
    constructor() {
        super();
        this.title = '';

        this.marketwireNyheder = false;
        this.marketwireFlash = false;
        this.borsNasdaq = false;
        this.borsFirstNorth = false;
        this.borsSpotlight = false;
        this.borsAndre = false;
        this.aktier = false;
        this.makro = false;
        this.avisuddrag = false;
        this.tophistorier = false;

        this.myStocks = false;
    }
}

export class ExternalFeedsSettings extends ItemSettings {
    title: string;
    feeds: ExternalFeeds[];

    constructor() {
        super();
        this.title = '';
        this.feeds = [];
    }
}


export class GraphSettings extends ItemSettings {
    graphRange: GraphRange;
    graphs: {name: string, isin: string}[];
    constructor() {
        super();
        this.graphRange = GraphRange.DAY_1;
        this.graphs = [{ name: 'OMX C25', isin: 'DK0060775369' }];
    }
}

export class StockfeedSettings extends ItemSettings {
    marketIndex: MarketIndex;
    sort: 'name'|'change'|'changePct';
    order: SortState;
    constructor() {
        super();
        this.showSettingsButton = false;
        this.marketIndex = MarketIndex.OMXCAP25;
        this.sort = 'name';
        this.order = 'desc';
    }
}
/** creates an instantiated component settings instance for the provided item type, with valid default values 
 * default values are defined in each settings class constructor
*/
export function CreateNewSettingInstance (itemType:ItemTypes) {
    switch (itemType) {
        case ItemTypes.NEWSFEED:
            return new NewsfeedSettings();
        case ItemTypes.GRAPH:
            return new GraphSettings();
        case ItemTypes.STOCKFEED:
            return new StockfeedSettings();
        case ItemTypes.EXTERNALFEEDS:
            return new ExternalFeedsSettings();
        case ItemTypes.CALENDAR:
            return new CalendarSettings();
        default:
            return new NewsfeedSettings();
    }
  }