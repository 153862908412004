import { create } from 'zustand'

const localStorageKey = 'dragndrop';

export interface DragNDropState {
  showTooltip: boolean,
  unlocked: boolean,
  dragSrcColId:string|null, // current dragging column
  updateUnlocked: (val: boolean) => void,
  updateDragSrcColId: (id: string|null) => void,

}

function isFirstVisit () :boolean {
  const exists = localStorage.getItem(localStorageKey);
  localStorage.setItem(localStorageKey, 'true');
  return exists ? false : true;
}

export const useDNDStore = create<DragNDropState>()((set) => ({
    showTooltip: isFirstVisit(),
    unlocked: false,
    dragSrcColId: null,
    updateUnlocked: (val: boolean) => set((state) => ({
      ...state,
      unlocked: val,
      showTooltip: false,
    })),
    updateDragSrcColId: (id: string|null) => set((state) => ({
      ...state,
      dragSrcColId: id,
    })),
}))