import { Button, Form, Modal } from "react-bootstrap";
import { exportLayout, layoutVersion, useLayoutStore } from "../stores/layout";
import LayoutService, { Layout, LayoutShareLevel, LayoutShareLevelOptions } from "../services/layoutService";
import { useSignal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime";
import { useNotifyStore } from "../stores/notify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDNDStore } from "../stores/dragndrop";
import { useAuth } from "bff-auth-lib";

type Props = {
    show: boolean;
    close: () => void;

}
export const SaveLayoutModal = (props:Props) => {
    useSignals();
    const layoutStore = useLayoutStore();
    const notifyCreate = useNotifyStore(state => state.create);
    const editLayout = useSignal<Layout|undefined>(undefined)
    const dndStore = useDNDStore();
    const queryClient = useQueryClient();
    const auth = useAuth();


    const handleOnShow = () => {
        editLayout.value = {
            name: '',
            version: layoutVersion,
            ownerOfLayout: true,
            data: exportLayout(),
            shareLevel: LayoutShareLevel.PERSONAL,
        }
    }

    const handleChangeLayoutName = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (!editLayout.value) return;
        const val = e.target.value;
        editLayout.value = {...editLayout.value, name: val}
    }

    const handleSaveEditLayout = async () => {
        if (!editLayout.value || editLayout.value.id) return;

        try {
            const result = await LayoutService.Create(editLayout.value);
            layoutStore.importLayout(auth, result.data, result.id);
            queryClient.invalidateQueries(['layoutList']);
            
            editLayout.value = undefined;
            dndStore.updateUnlocked(false);
            props.close();
        } catch (error:any) {
            notifyCreate({
                message: error?.message,
                bg: 'danger',
            });
            throw error;
        }
    }

    const handleOnChangeEditLayoutShareLevel = (event:React.ChangeEvent<HTMLSelectElement>) => {
        if (!editLayout.value) return;
        const val = parseInt(event.target.value) as LayoutShareLevel;
        editLayout.value = {...editLayout.value, shareLevel: val}
    }

    return <Modal show={props.show} onShow={handleOnShow}>
        <Modal.Header closeButton onHide={props.close}>Gem layout</Modal.Header>
        {
                editLayout.value ?
                <Modal.Body>
                    <Form.Label>Når du gemmer layoutet, kan det tilgås og deles fra indstillinger.</Form.Label>
                    <div className="d-flex">
                        <Form.Control autoFocus placeholder="Layout navn" onChange={handleChangeLayoutName} type="text" value={editLayout.value.name}/>
                        

                        {
                            auth.user?.roles.includes('terminal_admin') ?
                            <Form.Select
                                size="sm"
                                defaultValue={editLayout.value.shareLevel}
                                aria-label="Select share level"
                                style={{width: 105, borderColor: 'var(--bs-dark)', cursor: 'pointer'}}
                                className="ms-2 outline-dark"
                                onChange={handleOnChangeEditLayoutShareLevel}>
                                <option disabled>Deling</option>
                                { LayoutShareLevelOptions.map(x => <option key={x.value} value={x.value} label={x.label} />) }
                            </Form.Select>
                            : null
                        }
                        
                    </div>
            </Modal.Body>
                : null
        }

        <Modal.Footer>
            <Button className="ms-2" size="sm" variant="dark" onClick={props.close}>Luk</Button>
            <Button disabled={!editLayout.value?.name} onClick={handleSaveEditLayout} className="ms-2" size="sm" variant="success">Gem</Button>
        </Modal.Footer>

    </Modal>
}