import { create } from 'zustand'
import { v4 as uuid } from 'uuid';
import { GraphRange, ICompany, MarketIndex } from '../services/shareService';


export interface StockWindowState {
  isin: string,
  visible: boolean,
  show: (id:string, marketIndex:MarketIndex) => void,
  close: () => void,
  /** used to determine which cache to access for share prices, 
   * in order to grab the price for 1_DAY such that the prices
   *  for Stockfeed and StockWindow are identical in that range
   */
  marketIndex: MarketIndex,
  
  /** used for everyone who uses Stockfeed data (StockWindow & Graph components)
   * in order to determine when to re-render share values for GraphRange.1_DAY*/
  lastUpdate:Date,
  setLastUpdate: (date:Date) => void
}

export const graphColors = ['#3DD5F3', '#8C68CD', '#D63384', 'red'] 

export const graphRangeButtons = [
    { text: '1D',  value: GraphRange.DAY_1 },
    { text: '5D',  value: GraphRange.DAY_5 },
    { text: '6M',  value: GraphRange.MONTH_6 },
    { text: 'YTD', value: GraphRange.YTD },
    { text: '1Y',  value: GraphRange.YEAR_1 },
    { text: '3Y',  value: GraphRange.YEAR_3 },
    { text: '10Y', value: GraphRange.YEAR_10 },
]

export const useStockWindowStore = create<StockWindowState>()((set) => ({
    isin: '',
    visible: false,
    marketIndex: MarketIndex.OMXC,
    lastUpdate: new Date(),
    show: (isin: string, marketIndex:MarketIndex) => set((state) => ({
        ...state,
        isin,
        marketIndex,
        visible: true,
    })),
    close: () => set((state) => ({
        ...state,
        visible: false,
        isin: '',
    })),
    setLastUpdate: (date:Date) => set((state) => ({
        ...state,
        lastUpdate: date
    })),
}));