//https://www.abrahamberg.com/blog/aspnet-signalr-and-react/

import * as signalR from "@microsoft/signalr";
const URL = import.meta.env.VITE_SIGNALR_URL;
const secBeforeInvalidateCache:number = 15;


type DateEventListener = (date: Date) => void;
class DateEventManager {
  private listeners: DateEventListener[] = [];

  addListener(listener: DateEventListener) {
    this.listeners.push(listener);
  }

  removeListener(listener: DateEventListener) {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  emitDateEvent(date:Date) {
    this.listeners.forEach(listener => listener(date));
  }
}
export const invalidateCacheEvent = new DateEventManager();
/** signalR connection singleton */
class Connector {
    static instance: Connector;
    static lastConnected : Date|null;
    public connection: signalR.HubConnection;

    constructor() {
        Connector.lastConnected = new Date();
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL)
            .withAutomaticReconnect([0, 2000, 5000, 10_000, 30_000, 60_000])
            .configureLogging(signalR.LogLevel.Trace)
            .build();

        this.connection.onclose(error => {
            console.log('signalR connection closed');
            if (error) {
                console.error(error);
            }
            else {
                console.log("No error obj provided");
            }
            
            this.setStale();
        });
        this.connection.onreconnecting((error) => {
            console.log('signalR onreconnecting. lastConnected=' + Connector.lastConnected)
            this.setStale();
            if (error) {
                console.log('onreconnecting called due to error: ')
                console.error(error);
            }
            else {
                console.log("No error obj provided");
            }
            
        });
        this.connection.onreconnected(() => {
            console.log('signalR connection reconnected, lastConnected=' + Connector.lastConnected)
            this.unsetStale();
        })
    }

    /** only updates lastConnected if it is unset */
    public setStale() {
        if (Connector.lastConnected == null) {
            const now = new Date();
            Connector.lastConnected = now;
            console.log('lastConnected was set to: ' + now.toString())
        }

        console.log('setStale() called');
    }

    /** unsets stale, and optionally invokes an invalidateCacheEvent if shouldInvalidateCache() evalutes true */
    public unsetStale() {
        if (Connector.lastConnected != null && this.shouldInvalidateCache()) {
            console.log('emitting invalidate cache event');
            invalidateCacheEvent.emitDateEvent(Connector.lastConnected);
        }
        Connector.lastConnected = null;
        console.log('unsetStale() called')
    }
    public shouldInvalidateCache():boolean {
        function getTimeDifferenceInSeconds(date:Date) {
            const now_ms = Date.now();
            const date_ms = new Date(date).getTime();
            
            // Find the difference in milliseconds
            const difference_ms = Math.abs(date_ms - now_ms);
            
            // Convert back to seconds and return
            return Math.floor(difference_ms / 1000);
        }

        if (Connector.lastConnected != null) {
            const secondsElapsedSinceLastConnected = getTimeDifferenceInSeconds(Connector.lastConnected);
            if (secondsElapsedSinceLastConnected >= secBeforeInvalidateCache)
                return true;
        }
        return false;
    }

    /** moved from constructor so we can call this only after getting auth cookies */
    public async startConnection() {
        Connector.instance.connection.start()
            .then(() => {
                console.log('signalR connection startedd, lastConnected=' + Connector.lastConnected)
                console.log('connectionId: ' + Connector.instance.connection.connectionId);
                Connector.instance.unsetStale();
            })
            .catch(error => {
                console.log('signalR connection failed to start' + error?.message)
                console.error(error);
                if (Connector.instance.connection.state !== signalR.HubConnectionState.Reconnecting) {
                    Connector.instance.setStale();
                }
            });
    }
    
    /** returns true if reconnected, otherwise false */
    public async reconnectIfStale() {
        if (Connector.instance.connection.state == signalR.HubConnectionState.Disconnected) {
            try {
                console.log('trying to reconnect because disconnected')
                await Connector.instance.startConnection();
            } catch (error) {
                console.log('failed to reconnect stale signalR connection with error: ');
                console.error(error);
            }
        }
        else if (Connector.instance.connection.state == signalR.HubConnectionState.Connected) {
            console.log("not reconnecting because state: " + Connector.instance.connection.state)
            // manually unset lastConnected, so we dont invalidate the cache on next reconnect, when the connection was never lost
            // note: we know that the connection was never lost, because onreconnected was never called.
            Connector.lastConnected = null;
        }
    }

    /** gets the connection singleton */
    public static getInstance(): Connector {
        if (!Connector.instance) {
            Connector.instance = new Connector();
            console.log('created connector instance singleton')
        }
            
        return Connector.instance;
    }
}
export default Connector.getInstance;


