import apiClient from "../http-common";

export interface ICompany {
    id: number;
    name: string;
    isin: string | null;
    parentId: number;
    shortName: string | null;
    ticker: string | null;
    marketplaceId: number | null;
    shareprice: IShareSimpel | null;

    typeOfInstrument: InstrumentType;

    /** property added by websocket insert to determine if element should be animated */
    animate? : 'increase' | 'decrease' | undefined;
}

export enum InstrumentType {
    Unknown,
    Stock = 1,
    Bond = 2,
    InvestmentFund = 3,
    Currency = 4,
    Index = 5
}

export interface IShareSimpel {
    shareId: number;
    iDate: number;
    price: number;
    ask: number;
    bid: number;
    dayHigh: number;
    dayLow: number;
    volume: number;
    highLowType: number;
    high: number;
    low: number;
    iDateHigh: number;
    iDateLow: number;
    name: string;
    changeType: number;
    changeName: string;
    priceHistoric: number;
    iDateHistoric: number;
    change: number;
    changePct: number;
    iSIN: string;
}

export enum MarketIndex {
    OMXC = 11,
    OMXCAP25 = 12,
    MY_SHARES = 100
}

export interface GraphPoint {
    date: string,
    price: number,
    volume: number
}

export interface GraphData {
    name: string,
    isin: string,
    data: GraphPoint[],
    historicPrice: GraphPoint,
}

export enum GraphRange {
    ALL,
    DAY_1,
    DAY_5,
    MONTH_6,
    YTD,
    YEAR_1,
    YEAR_3,
    YEAR_10
}

export interface ShareNames {
    companyId: number,
    isin: string,
    name: string,
    shareNamesType: string,
}

export enum ShareChangeType {
    DAGENS_AENDRING = 1,
    UGENS_AENDRING = 2,
    MAANEDS_AENDRING = 3,
    N52_UGER = 5,
    AARETS_AENDRING = 6,
    FORIGE_DAGS_AENDRING = 7,
    LOEBENDE_3_AAR = 9,
    LOEBENDE_6_MDR = 10,
    N5_HANDELSDAGE = 11,
    N1_AAR = 12,
    ALLE_AAR = 13,
    N10_AAR = 14
}

export interface ShareChange {
    changeType: ShareChangeType;
    changeName: string;
    priceHistoric: number;
    dateHistoric: string;
    change: number;
    changePct: number;
}
export interface ShareHighLow {
    highLowType: number;
    high: number;
    low: number;
    dateHigh: string;
    dateLow: string;
    name: string;
}

export interface ShareCompany {
    changes: ShareChange[];
    highLow: ShareHighLow[];
    iSIN: string;
    name: string;
    volume: number;
    price: number;
    ticker: string;
}

/** Service for fetching data from the News controller. */
export default class ShareService {
    static readonly controller = '/share';

    static async GetListSimpel(marketIndex:MarketIndex) {
        const response = await apiClient.get<ICompany[]>(`${this.controller}/LastTrade/GetListSimpel?marketIndex=${marketIndex}`);
        return response.data;
    }

    static async GetGraphData(isin:string, graphRange:GraphRange) {
        const response = await apiClient.get<GraphData>(`${this.controller}/Historic/GetGraphData?isin=${isin}&graphRange=${graphRange}`);
        return response.data;
    }

    static async GetShareCompanies() {
        const response = await apiClient.get<ShareNames[]>(`${this.controller}/ShareCompanies`);
        return response.data;
    }

    static async GetCompanyHighLowAndChanges(isin:string) {
        const response = await apiClient.get<ShareCompany>(`${this.controller}/ShareHighLowAndChanges?isin=${isin}`);
        return response.data;
    }
}