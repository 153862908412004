import React from "react";
import { Button, InputGroup } from "react-bootstrap"
import { isMobile } from "react-device-detect";

type Props = {
    searchText: string,
    setSearchText: React.Dispatch<React.SetStateAction<string>>,
    onChange?: (text:string) => void,
    fill? : boolean,
}
export const Search = ({ searchText, setSearchText, onChange, fill }:Props) => {
    const isSearching = searchText.length > 0;

    const onSearchInput = (event:React.ChangeEvent<HTMLInputElement>) =>  {
        setSearchText(event.target.value);
        if (onChange !== undefined) {
            onChange(event.target.value);
        }
    }

    const onClear = () => {
        setSearchText('');
        if (onChange !== undefined) {
            onChange('');
        }
    }
    const width = fill ? '100%' : isSearching ? 300 : 100;
    
    return (
    <InputGroup size={isMobile ? undefined : 'sm'}>
        <input type="text" className={`form-control ${isMobile ? '' : 'form-control-sm'} ${searchText && 'border-end-0 border-end-fix'}`} style={{maxWidth: width}} value={searchText} placeholder="Søg" onChange={onSearchInput}/>
        { searchText && <span className="input-group-append">
            <Button onClick={onClear}
                variant="search"
                size='sm'
                className="">
                <i className="bi bi-x-circle-fill"></i>
            </Button>
        </span> }
        
    </InputGroup>
    )
}