import React, { useEffect, useRef } from 'react';

interface Props {
  rawHtml: string;
  searchString?: string;
}

const RawHtmlComponent: React.FC<Props> = ({ rawHtml, searchString }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (containerRef.current) {
            const parser = new DOMParser();
            rawHtml = makeLinksClickable(rawHtml);
            rawHtml = encapsulateTables(rawHtml);
            const parsedHtml = parser.parseFromString(rawHtml, 'text/html');

            /** make existing <a> links open in new window */
            const anchors = parsedHtml.getElementsByTagName('a');
            for (let i = 0; i < anchors.length; i++) {
                const anchor = anchors[i];
                anchor.setAttribute('target', '_blank');
            }

            if (searchString) {
                // split searchstring by spaces, since that's how elastic searching behaves
                const searchStringSplit = searchString.split(' ');
                
                for (let index = 0; index < searchStringSplit.length; index++) {
                    const searchStringI = searchStringSplit[index];
                    // Prevent searching for empty strings, e.g. if the user adds several space characters
                    if (searchStringI.trim() == "") continue;
                    // Escape special characters in the search string
                    // const escapedSearchString = searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

                    /** give search string matches with yellow background*/
                    // https://stackoverflow.com/questions/8474031/case-insensitive-xpath-contains-possible
                    // better implementation possible but browsers dont support XPATH 2.0 yet for some reason
                    const textNodes = parsedHtml.evaluate(
                        `//text()[contains(translate(., '${searchStringI.toUpperCase()}', '${searchStringI.toLowerCase()}'), '${searchStringI.toLowerCase()}')]`,
                        parsedHtml,
                        null,
                        XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
                        null
                    );

                    for (let i = 0; i < textNodes.snapshotLength; i++) {
                        const textNode = textNodes.snapshotItem(i);
                        if (textNode === null) continue;
                        const spanElement = parsedHtml.createElement('span');
                        if (!textNode.nodeValue) continue;

                        const matchedText = textNode.nodeValue;
                        const highlightedText = matchedText.replace( 
                            new RegExp(`((?:^|\\s)${searchStringI}(?=\\s|[,.-]|$))`, 'gi'),
                            '<mark>$&</mark>'
                            ).replaceAll('<mark> ', ' <mark>') // move the space (if exists) out of the yellow marking

                        
                        spanElement.innerHTML = highlightedText
                        
                            
                        textNode.parentNode?.replaceChild(spanElement, textNode);
                    }   
                }
                
            }

            containerRef.current.innerHTML = parsedHtml.body.innerHTML;
        }
    }, [rawHtml, searchString]);

    function escapeRegExp(text: string) {
        return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function makeLinksClickable(content:string) {
        const linkRegex = /(((https?):\/\/|www\.)[^"<\s()]+)(?![^<>]*>|[^"]*?<\/a)/g
        return content.replace(linkRegex, (match) => {
            const withProtocol = match.startsWith('http') ? match : `http://${match}`;
            return`<a href="${withProtocol}" target="_blank">${match}</a>`;
        });
    }
    function encapsulateTables(content:string) {
        content = content.replaceAll('<table', '<div class="table-scroll"><table');
        content = content.replaceAll('</table>', '</table></div>')
        return content;
    }

    return <div ref={containerRef}></div>;
};

export default RawHtmlComponent;