
import { Nav, Container, Navbar, Button, Form, DropdownButton, Dropdown, Overlay, Tooltip } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useDNDStore } from '../../../stores/dragndrop';
import logo_dark from '../../../assets/marketwire_dark.svg';
import logo_white from '../../../assets/marketwire_white.svg';
import { useAuth } from 'bff-auth-lib';
import { SelectableTheme, useThemeStore } from '../../../stores/theme';
import { useLayoutStore } from '../../../stores/layout';
import { selectOptions } from '../../../types/componentSettings'
import { GetItemBasedOnType, ItemProps } from '../ColumnItem';
import { signal, useComputed, useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
type Props = {
  showSettingsModal: () => void
}

export const mobileItemIndex = signal<number>(0);

export const MobileNavMenu = (props:Props) => {
  useSignals();
  const themeStore = useThemeStore();
  const authContext = useAuth();
  const userRealNameAbbreviation = authContext?.user?.userProfile?.usrRealName?.split(' ')?.map(x => x[0]);
  const layoutStore = useLayoutStore();
  const flattenedItemArray = layoutStore.columns.flatMap(column => column.items.map((item, itemIndex) => (
      { columnId: column.id, item, itemIndex, label: selectOptions.find(x => x.value == item.info.itemType)?.label }
  )));
  // for mobile view
  const onChangeItemIndex = (e:React.ChangeEvent<HTMLSelectElement>) => {
      const indexStr = e.currentTarget.value;
      const index = Number.parseInt(indexStr);
      mobileItemIndex.value = index;
  }
  const currentItemProps = useComputed(() => {
    const currentFlatItem = flattenedItemArray[mobileItemIndex.value]
    const currentItemProps:ItemProps = {
        columnId: currentFlatItem.columnId,
        index: currentFlatItem.itemIndex,
        itemsLength: 1,
        info: currentFlatItem.item.info,
        id: currentFlatItem.item.id
    }
    return currentItemProps;
});

const itemSettings = useComputed(() => {
  return flattenedItemArray[mobileItemIndex.value].item.info.itemSettings;
})


  return (
    <Navbar bg="d-fixed bg-light border-bottom border-gray" style={{ height: "45px" }}>
      <Container className="mw-100 px-2">
        <Navbar.Brand className="">
          <img
            src={themeStore.theme === 'dark' ? logo_white : logo_dark}
            height="25"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Nav>
          {
            itemSettings.value?.showSettingsButton &&
            <Button className="rounded me-1" size="sm" variant='outline-dark' onClick={() => layoutStore.modalShow(currentItemProps.value.columnId, currentItemProps.value.index)}>
              <i className="bi bi-gear"></i>
            </Button>
          }
          
          <Form.Select
            defaultValue={0}
            size="sm" aria-label="Select theme"
            style={{width: 135, borderColor: 'var(--bs-dark)', cursor: 'pointer', color: 'var(--bs-dark)', backgroundColor: 'var(--bs-light)'}}
            className="me-1 outline-dark"
            onChange={onChangeItemIndex}>
            {flattenedItemArray.map((x, i) => (
              <option value={i} key={i}>{x.label}</option>
            ))}
          </Form.Select>

          <Button
            size="sm"
            className="ms-1"
            variant='outline-dark'
            onClick={props.showSettingsModal}
            >
              {userRealNameAbbreviation || 'Profile'}
              <i className="bi bi-person ms-1" />
          </Button>
        </Nav>
      </Container>
    </Navbar>
  )
}