import { AuthContextProps } from "bff-auth-lib/dist/lib/AuthContext";
import { LayoutStateValues } from "../stores/layout";
import { ItemTypes, NewsfeedSettings } from "../types/componentSettings";
import { produce } from "immer";
import { GenerateHeaderTitle as GenerateNewsfeedTitle } from "../components/items/Newsfeed";

export class AuthHelper {
    static hasRole(auth:AuthContextProps, role:AuthRole) {
        if (!auth.user) return false;

        return auth.user.roles.map(x => x.toLocaleLowerCase()).includes(role.toLowerCase());
    }
    static hasAnyRole(auth:AuthContextProps, roles:AuthRole[]) {
        for (let i = 0; i < roles.length; i++) {
            const role = roles[i];
            const result = this.hasRole(auth, role);
            if (result) return true;
        }
        return false;
    }
    static hasRoles(auth:AuthContextProps, roles:AuthRole[]) {
        for (let i = 0; i < roles.length; i++) {
            const role = roles[i];
            const result = this.hasRole(auth, role);
            if (!result) return false;
        }
        return true;
    }

    /** returns true or false if layout is allowed according to users roles, or undefined if user object is not set */
    static layoutIsAllowed(auth:AuthContextProps, layout:LayoutStateValues):boolean|undefined {
        if (!auth.user) return undefined;
        const roles = auth.user?.roles;

        for (let i = 0; i < layout.columns.length; i++) {
            const col = layout.columns[i];
            for (let j = 0; j < col.items.length; j++) {
                const item = col.items[j];
                switch (item.info.itemType) {
                    case ItemTypes.NEWSFEED:
                        const nSettings = item.info.itemSettings as NewsfeedSettings;
                        if (nSettings.marketwireFlash && !roles.includes(AuthRole.FLASH))
                            return false;

                        break;
                    case ItemTypes.CALENDAR:
                    
                        break;
                    case ItemTypes.GRAPH:
                    
                        break;
                    case ItemTypes.STOCKFEED:
                    
                        break;
                    case ItemTypes.EXTERNALFEEDS:
                        
                        break;
                    default:
                        break;
                }
            }
        }
        return true;
    }
    /** Ensure a layout does not include items or values that require roles that the user does not have.
     * Returns the fixed, safe layout.
    */
    static fixLayoutBasedOnUserRoles(auth:AuthContextProps, layout:LayoutStateValues):LayoutStateValues {

        if (!auth.user) {
            console.warn('auth user was not loaded when trying to fixLaoutBasedOnUserRoles.');
            return layout;
        }

        const fixedLayout = produce(layout, draft => {
            // iterate backwards to prevent indexing problems when removing items/columns
            for (let i = draft.columns.length - 1; i >= 0; i--) {
                const col = draft.columns[i];
                for (let j = col.items.length - 1; j >= 0; j--) {
                    const item = col.items[j];
                    switch (item.info.itemType) {
                        case ItemTypes.NEWSFEED:
                            const nSettings = item.info.itemSettings as NewsfeedSettings;
                            if (nSettings.marketwireFlash && !this.hasRole(auth, AuthRole.FLASH))
                            {
                                // check if other settings are enabled
                                const otherSettingEnabled =
                                       nSettings.aktier
                                    || nSettings.avisuddrag
                                    || nSettings.borsAndre
                                    || nSettings.borsFirstNorth
                                    || nSettings.borsNasdaq
                                    || nSettings.borsSpotlight
                                    || nSettings.tophistorier
                                    || nSettings.makro
                                    || nSettings.marketwireNyheder;

                                if (otherSettingEnabled) {  //remove flash setting
                                    nSettings.marketwireFlash = false;
                                    nSettings.title = GenerateNewsfeedTitle(nSettings);
                                }
                                else { // remove item
                                    col.items.splice(j, 1);
                                }
                            }
                            break;
                        case ItemTypes.CALENDAR:
                            break;
                        case ItemTypes.GRAPH:
                            break;
                        case ItemTypes.STOCKFEED:
                            break;
                        case ItemTypes.EXTERNALFEEDS:
                            break;
                        default:
                            break;
                    }
                }
            }

        })
        return fixedLayout;

    }

}

export enum AuthRole {
    FLASH = 'terminal_flash',

}