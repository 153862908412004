import { StrictMode, useRef, useState } from 'react'
import { ColumnContainer } from './ColumnContainer'
import { useLayoutStore } from '../../stores/layout'
import { useDNDStore } from '../../stores/dragndrop'
import { DragDropContext, DragStart, DraggableLocation, DropResult } from '@hello-pangea/dnd'
import { klona } from 'klona'
import { produce } from 'immer'

type DragDropProps = (source: DraggableLocation, destination: DraggableLocation) => void

export const MainContainer = () => {
    const layoutStore = useLayoutStore();
    const dndStore = useDNDStore();
    const moveRowDifferentColumn: DragDropProps = (source, destination) => {
        const newcols = produce(layoutStore.columns, draft => {
            const srcColIndex = layoutStore.columns.findIndex(x => x.id === source.droppableId);
            const dstColindex = layoutStore.columns.findIndex(x => x.id === destination.droppableId);
            draft[dstColindex].items.splice(destination.index, 0, draft[srcColIndex].items[source.index]);
            draft[srcColIndex].items.splice(source.index, 1);
        });

        layoutStore.updateColumns(newcols);
    }
    const moveRowSameColumn: DragDropProps = (source, destination) => {
        const newColumns = produce(layoutStore.columns, draft => {
            const columnIndex = layoutStore.columns.findIndex(x => x.id === destination.droppableId)
            const [removedItem] = draft[columnIndex].items.splice(source.index, 1);
            draft[columnIndex].items.splice(destination.index, 0, removedItem);
        })

        layoutStore.updateColumns(newColumns);
    }
    const onDragEnd = (result:DropResult) => {
        dndStore.updateDragSrcColId(null); // reset since nothing is being dragged.

        if (!result.destination) return;

        const { destination, source } = result;

        if (source.droppableId !== destination.droppableId) {
            moveRowDifferentColumn(source, destination)
         } else {
            moveRowSameColumn(source, destination)
         }
         
    }

    const onDragStart = (val:DragStart) => {
        dndStore.updateDragSrcColId(val.source.droppableId);
    }
    
    return (
        <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            
            >
            <div className="vw-100 overflow-x-auto d-flex" style={{height: 'calc(100vh - 45px)'}}>
                {layoutStore.columns.map((col, index) => {
                    return <ColumnContainer key={col.id} {...col} />
                })}
            </div>
        </DragDropContext>
        
    )
}
