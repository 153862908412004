import { useState } from 'react'
import { useNotifyStore } from '../stores/notify'
import { CloseButton, Toast, ToastContainer } from 'react-bootstrap';

/** The bg colors for which the close button should be white */
const whiteCloseBtnThemes = new Set<string>([
    'dark',
    'primary',
    'secondary',
    'info',
    'success',
    'danger',
])

/** Component responsible for showing all notifications created in the notification store,
 *  as well as removing them when they have expired (defined by duration property)
 */
export const Notifications = () => {
    const notifications = useNotifyStore(state => state.notifications);
    const remove = useNotifyStore(state => state.remove);

    const getVariant = (bg:string|undefined) => bg && (whiteCloseBtnThemes.has(bg) ? 'white' : undefined)
    
    return (
        <ToastContainer position='top-center' className="mt-2">
            {notifications.map(item => {
                    return (
                        <Toast
                            key={item.id} autohide
                            delay={item.duration ?? 4000}
                            className={item.bg && `text-bg-${item.bg}`}
                            onClose={() => remove(item.id)}
                            
                        >
                            { item.header ?
                            <>
                                <Toast.Header closeVariant={getVariant(item.bg)}>
                                    <strong className="me-auto">{item.header}</strong>
                                    {/* <small className="text-muted">mins ago</small> */}
                                </Toast.Header>
                                <Toast.Body>{item.message}</Toast.Body>
                            </>
                                :
                                <div className="d-flex">
                                    <Toast.Body>{item.message}</Toast.Body>
                                    <span className="m-2 mb-auto ms-auto">
                                        <CloseButton variant={getVariant(item.bg)} onClick={() => remove(item.id)}/>
                                    </span>
                                </div>
                                
                            }
                        </Toast>
                    )
                })
            }
        </ToastContainer>
      )
}

