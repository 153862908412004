import React from "react"

/** invisible overlay that takes up 80% of containers height (independent of scroll height)*/
export const LoadAreaTrigger = React.forwardRef<any>((props, ref) => {
    return (
        <div style={{position: 'relative'}}>
            <div ref={ref} style={{opacity: 0, pointerEvents: 'none', position: 'absolute', bottom: 0, left: 0, right: 0, height: innerHeight * 0.6}}></div>
        </div>
    )
});
