import { ListGroup } from 'react-bootstrap'
import { LayoutColumn, LayoutItem } from '../../stores/layout'
import { Draggable  } from '@hello-pangea/dnd'

import { Newsfeed } from '../items/Newsfeed'
import { Graph } from '../items/Graph'
import { Stockfeed } from '../items/Stockfeed'

import { useDNDStore } from '../../stores/dragndrop'
import { ItemTypes } from '../../types/componentSettings'
import { ExternalFeeds } from '../items/ExternalFeeds'
import { Calendar } from '../items/Calendar'

export type ItemProps = LayoutItem & {
    columnId: string,
    index: number,
    itemsLength: number,
}

function getItemStyle (isDragging:boolean, draggableStyle:any, unlockMode:boolean, itemCount:number, fixedHeight:number|null) {
    const styles =  {
        ...draggableStyle,
        userSelect: "none",

        // change background color if dragging
        background: isDragging ? "lightgreen" : "",
      }
      if (fixedHeight) {
        // styles.height = fixedHeight;
        styles.minHeight = fixedHeight;

        if (itemCount == 1)
        {
            styles.maxHeight = fixedHeight;
        }
        else {
            styles.height = '20%';
            if (!unlockMode) {
                styles.flexGrow = 1;
            }
        }
      }
    return styles;
}


export const GetItemBasedOnType = (props:ItemProps) => {
    switch (props.info.itemType) {
        case ItemTypes.NEWSFEED:
            return <Newsfeed {...props} />;
        case ItemTypes.GRAPH:
            return <Graph {...props} />
        case ItemTypes.STOCKFEED:
            return <Stockfeed {...props} />
        case ItemTypes.EXTERNALFEEDS:
            return <ExternalFeeds {...props} />
        case ItemTypes.CALENDAR:
            return <Calendar {...props} />
        default: //not implemented
            return <div></div>
    }
}

const GetFixedHeightBasedOnType = (type:ItemTypes) => {
    switch (type) {
        case ItemTypes.GRAPH:
            return 400;

        default: //not implemented
            return null;
    }
}


export const ColumnItem = (props:ItemProps) => {
    const dndStore = useDNDStore(state => state);

    return (
        <Draggable
            isDragDisabled={!dndStore.unlocked}
            draggableId={props.id}
            index={props.index}>
                {(provided, snapshot) => (
                    <div className={'d-flex flex-column p-2 rounded' + (!GetFixedHeightBasedOnType(props.info.itemType) ? (dndStore.unlocked ? ' h-20' : ' h-20 flex-grow-1') : '')}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            dndStore.unlocked,
                            props.itemsLength,
                            GetFixedHeightBasedOnType(props.info.itemType)
                          )}
                    >
                        {GetItemBasedOnType(props)}
                    </div>
                )}
            
        </Draggable>

    )
}