
import { useAuth } from 'bff-auth-lib';
import { useState } from 'react';
import { Button, CloseButton, ListGroup, Modal, Col, Nav, Row, Tab, Container, ListGroupItem, Form, OverlayTrigger, Tooltip, Popover, Badge} from 'react-bootstrap';
import LayoutService, { Layout, LayoutShareLevel, LayoutShareLevelOptions } from '../../services/layoutService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { b64_to_utf8, exportLayout, layoutVersion, useLayoutStore, utf8_to_b64 } from '../../stores/layout';
import { useNotifyStore } from '../../stores/notify';
import { MyCompaniesContent } from './MyCompaniesContent';
import { LayoutContent } from './LayoutContent';
import { NewsfeedQueryKey } from '../items/Newsfeed';
import { CalendarQueryKey } from '../items/Calendar';
import { MarketIndex } from '../../services/shareService';
import { isMobile } from 'react-device-detect';
import { MyCompanyDTO } from '../../services/myCompaniesService';
import { compareAsc, compareDesc } from '../../helpers/miscHelper';

type Props = {
    visible: boolean,
    close: () => void,
}
export const SettingsModal = (props:Props) => {
    const auth = useAuth();
    const queryClient = useQueryClient();
    const [companiesChanged, setCompaniesChanged] = useState<boolean>(false);
    const reloadCompaniesRequired = ()  => setCompaniesChanged(true);
    const handleClose = async () => {
        if (companiesChanged) {
            // reset
            setCompaniesChanged(false);

            // invalidate queries for calendar and newsfeed (stockfeed does not use separate cache for MyStocks)
            // newsfeed list query
            await queryClient.invalidateQueries({
                predicate: function ({ queryKey }) {
                    if (queryKey[0] === 'newsList') 
                    {
                        const qk = queryKey[1] as NewsfeedQueryKey;
                        if (qk.myStocks)
                        {
                            return true;
                        }
                        }
                    return false
                    }
            }, { });
            // calendar list query
            await queryClient.invalidateQueries({
                predicate: function ({ queryKey }) {
                    if (queryKey[0] === 'calendarList') {
                        const qk = queryKey[1] as CalendarQueryKey;
                        if (qk.myStocks) return true;
                    }
                    return false
                }
            });
            queryClient.setQueriesData<MyCompanyDTO[]>(["myCompanyList"], (oldData:MyCompanyDTO[]|undefined) => {
                return produce(oldData, draft => {
                    draft?.sort((a, b) => compareDesc(a.companyName.toLocaleLowerCase(), b.companyName.toLocaleLowerCase()));
                });
            })
            await queryClient.invalidateQueries({ queryKey: ['getSimpelShare', MarketIndex.MY_SHARES] });
        }

        props.close();
    }

    const logoutElm = <div className="mt-auto fs-7">
    <div className="">Du er logget ind som</div>
    <div className="text-muted"><b>{auth?.user?.userProfile?.usrRealName}</b></div>
    <Button className="mt-1" size="sm" variant="outline-dark" href={auth?.user?.userProfile?.bffLogoutUrl}>
        <i className="bi bi-box-arrow-right me-2"></i>Log ud
    </Button>
</div>

    return (
        <Modal show={props.visible} size="lg" onHide={handleClose} fullscreen="sm-down" className="h-100">
            <Modal.Header closeButton> 
                <Modal.Title className="text-truncate">Indstillinger</Modal.Title>
            </Modal.Header>
            <Modal.Body className="h-100">
                <Tab.Container id="settings-window-container" defaultActiveKey="first">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column h-100">
                            <Nav.Item>
                                <Nav.Link eventKey="first"><i className="bi bi-person me-2"></i>Mine aktier</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second"><i className="bi bi-sliders me-2"></i>Layout og udseende</Nav.Link>
                            </Nav.Item>

                            {!isMobile && logoutElm}
                            
                            </Nav>
                        </Col>
                        <Col sm={9} className="h-100" >
                            <Tab.Content className="overflow-y-auto h-100">
                                {isMobile && <hr />}
                                <Tab.Pane eventKey="first"><MyCompaniesContent setReloadRequired={reloadCompaniesRequired} show /></Tab.Pane>
                                <Tab.Pane eventKey="second"><LayoutContent show /></Tab.Pane> 
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Modal.Body>
            {
            isMobile &&
                <Modal.Footer className="d-inline-block">
                 {logoutElm}
                </Modal.Footer>
            }
        </Modal>
    );
}



