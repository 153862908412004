import apiClient from "../http-common";
import { ICompany, IShareSimpel } from "./shareService";

export interface INewsDTO {
    myStocks: boolean,
    companyIds?: number[],
    filterFlags: NewsFilterFlags,
    limit: number
    fromDate?: string,
    isin?: string[],
}

export interface INewsShort {
    id: string,

    formatId: number,
    sectionId: number,

    formatName: string,
    sectionName: string,
    finansHeadline: string,
    journalistHeadline?: string,
    dateInserted: string,
}

export enum NewsFilterFlags {
    MARKETWIRE_NYHEDER  = 1 << 0,   //1
    MARKETWIRE_FLASH    = 1 << 1,   //2
    BORS_NASDAQ         = 1 << 2,   //3
    BORS_FIRST_NORTH    = 1 << 3,   //4
    BORS_SPOTLIGHT      = 1 << 4,   //5
    BORS_ANDRE          = 1 << 5,   //6
    AKTIER              = 1 << 6,   //7
    MAKRO               = 1 << 7,   //8
    AVISUDDRAG          = 1 << 8,   //9
    TOPHISTORIER        = 1 << 9   //10
}

export interface IMediaItem {
    id: number;
    headline: string | null;
    uid: string;
    subtitle: string | null;
    photoCredit: string;
    filenameOrg: string;
    multimediaBureau: string;
}
export interface ISubjectCode {
    id: number,
    name: string,
}
export enum EmbedType {
    Weblinks = 1,
    Twitter,
    Facebook,
    Youtube,
    FinansData,
    Instagram,
    ScanpixVideo
}
export interface Embed {
    embedTypeId: EmbedType,
    embedType: string,
    headline: string,
    /** this is a html string that we have to embed */
    embedCode: string,
    subtitle: string,
    strapline: string,  
}
export interface INewsDetail extends INewsShort {
    strapline: string,
    subtitle?: string,
    content?: string,
    contactEmail?: string,
    contactPhone?: string,
    contactName?: string,

    mediaItems?: IMediaItem[],
    subjectCodes?: ISubjectCode[],
    companies?: ICompany[],
    embeds?: Embed[]
}


/** Service for fetching data from the News controller. */
export default class NewsService {
    static readonly controller = '/news';

    static async GetList(query:INewsDTO) {
        const response = await apiClient.post<INewsShort[]>(`${this.controller}/getList`, query);
        return response.data;
    }

    static async GetDetail(id:string) {
        const response = await apiClient.get<INewsDetail>(`${this.controller}/getDetail?id=${id}`);
        return response.data;
    }

    static async GetFlashList(id:string) {
        const response = await apiClient.get<INewsShort[]>(`${this.controller}/getNewsFlashList?newsId=${id}`);
        return response.data;
    }
}