import React, { useEffect, useLayoutEffect, useRef } from "react";

type Props = {
  html: string,
}

/** We need to use this to execute scripts from a html string, because dangerouslySetInnerHTML does not execute scripts.
 */
export const DangerousHTMLWithScripts = React.memo(({html}:Props) => {
  const ref = useRef<HTMLDivElement>(null);

  // important to not have ANY deps
  useEffect(() => {
    if (!ref.current) return;
    const container = ref.current;
    
    // Find all script tags in the HTML content
    const scripts = container.querySelectorAll("script");
    
    // Execute each script
    scripts.forEach((script) => {
      const scriptTag = document.createElement('script');
      scriptTag.text = script.innerHTML;

      // Copy attributes like 'src' if present
      Array.from(script.attributes).forEach(attr =>
        scriptTag.setAttribute(attr.name, attr.value)
      );

      container.appendChild(scriptTag);
    });

  }, [html]);

  return <div ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
});
