import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounceEffect, useDebounce, } from "@better-hooks/performance";
import { Button, InputGroup, ListGroup } from 'react-bootstrap'
import { ItemTemplate } from './ItemTemplate'
import { ItemProps } from '../layout/ColumnItem'
import {  ExternalFeedsSettings } from '../../types/componentSettings'
import { ReaderTypes, useReaderStore } from '../../stores/reader'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { toLocalDateTimeStr } from '../../helpers/dateHelper'
import { useNotifyStore } from '../../stores/notify'
import ExternalFeedService, { IExternalFeedsDTO, ExternalFeedNews, ExternalFeedMap } from '../../services/externalFeedService';
import { Search } from '../shared/Search';
import { LoadAreaTrigger } from '../shared/LoadAreaTrigger';
import { isMobile } from 'react-device-detect';
import { useLayoutStore } from '../../stores/layout';
import { useDNDStore } from '../../stores/dragndrop';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
export const ExternalFeedsItems = signal<Set<string>>(new Set<string>())

export function GenerateHeaderTitle(settings:ExternalFeedsSettings):string {
    const defaultTitle = 'Andre Medier';
    if (isMobile) return defaultTitle;
    if (settings.title) return settings.title;
    const separator = ', ';
    let str = '';

    for (let i = 0; i < settings.feeds.length; i++) {
        const feed = settings.feeds[i];
        const feedName = ExternalFeedMap.get(feed)?.label;
        str += feedName + separator;
    }
    
    if (str.length > 0) {
        if (str.length > 67)
        {
            str = defaultTitle;
        }
        else {
            str = str.substring(0, str.length - separator.length)
        }
    }

    return str;
}

export const ExternalFeeds = (props:ItemProps) => {
    useSignals();
    const bodyRef = useRef<HTMLDivElement>(null);
    const settings:ExternalFeedsSettings = props.info.itemSettings;
    const showReader = useReaderStore(state => state.show);
    const notifyCreate = useNotifyStore(state => state.create);
    const { ref: inViewRef, inView } = useInView({
        initialInView: false,
    })

    const {debounce, reset, active} = useDebounce({ delay: 300 })

    // search logic
    const [searchText, setSearchText] = useState<string>('');
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>('')
    const isSearching = searchText.length > 0;

    // dto based on newsfeed settings. fromDate property is added dynamically
    const baseDTO:IExternalFeedsDTO = {
        skip: 0,
        limit: 15,
        feeds: settings.feeds,
    }
    const query = useInfiniteQuery<ExternalFeedNews[], Error>({
        queryKey: ['externalFeedNews', settings.feeds, debouncedSearchText], 
        queryFn: async ({pageParam = 0}) => {
            try {
                return await ExternalFeedService.GetList({ ... baseDTO, skip: pageParam, searchText: debouncedSearchText})
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        getNextPageParam: (lastPage, allPages) => {
            const nextPage =
              lastPage.length >= baseDTO.limit ? allPages.flat().length : undefined;
            return nextPage;
          },
        retry: true,
        staleTime: Infinity, // prevent refetching old data
    });
    
    const flatData = useMemo<ExternalFeedNews[] | undefined>(() => {
        return query.data?.pages.flat();
    }, [query.data]);
    

    const applySearchInput = (searchStr:string) => {
        debounce(() => {
            // debounced logic
            setDebouncedSearchText(searchStr);
            // scroll to top
            bodyRef.current?.scrollTo(0, 0);
        });
    }

    // debounce as it otherwise gets called way too many times
    useDebounceEffect(() => {
        if (inView) {
            query.fetchNextPage();
        }
    }, { delay: 100 }, [query.data, debouncedSearchText, inView] )


    const unsetItemNew = (item:ExternalFeedNews) => {
        ExternalFeedsItems.value.delete(item.id);
    }

    // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
    const openInNewTab = (url: string): void => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const renderedData = (data:ExternalFeedNews[]|undefined) => {
        return data?.map((item, index) => {
            return (
                <li key={item.id + props.columnId + props.index}
                    className={`list-group-item list-group-item-action d-flex p-2 justify-content-between fs-7 ${ExternalFeedsItems.value.has(item.id) && 'bg-animate-insert'}`}
                    onAnimationEnd={() => unsetItemNew(item)}
                    onClick={() => openInNewTab(item.url)}
                    style={{cursor:'pointer'}}
                >
                    <span className="all-wrap">
                        {item.title}
                    </span>
                    <span className="text-muted ms-1" style={{minWidth: 'max-content'}}>
                        {toLocalDateTimeStr(item.datePublished)}
                    </span>
                </li>
            )
        })
    }

    return (
        <ItemTemplate { ...props } alwaysShowTitle={true} title={GenerateHeaderTitle(settings)} isLoading={query.isLoading}>
            {/* Header */}
            <>
                <InputGroup size='sm'>
                    <Search searchText={searchText} setSearchText={setSearchText} onChange={applySearchInput} />
                </InputGroup>
            </>
            {/* Body */}
            <div className="bg-white px-1 overflow-auto w-100 rounded-bottom" ref={bodyRef}>
                <ListGroup variant="flush" as="ol" className="h-100">
                {
                    renderedData(flatData)
                }
                {
                    (query.hasNextPage) && <div className="text-center text-muted">
                        <LoadAreaTrigger ref={inViewRef} />
                        Loading...
                    </div>
                }
                {
                    (!query.hasNextPage) && <div className="text-center text-muted">Ikke flere resultater</div>
                }
                </ListGroup>
            </div>
        </ItemTemplate>
    )
}