import React, { useEffect, useState } from 'react';
import { Badge, Card, CloseButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { ReaderTypes, useReaderStore } from '../../stores/reader';
import { NewsfeedRW } from './NewsfeedRW';
import { NewsfeedFlashRW } from './NewsfeedFlashRW';
import { useDNDStore } from '../../stores/dragndrop';
// https://github.com/gregnb/react-to-print
export const ReaderWindow = () => {
    const readerStore = useReaderStore();
    const unlocked = useDNDStore(state => state.unlocked)

    // close window on escape key
    useEffect(() => {
      function handleEscapeKey(event: KeyboardEvent) {
        if (event.code === 'Escape') {
          readerStore.close();
        }
      }
    
      document.addEventListener('keydown', handleEscapeKey)
      return () => document.removeEventListener('keydown', handleEscapeKey)
    }, [])

    return (
      <>
      { readerStore.visible && !unlocked ?
      <div className='reader-window'>
          <Card className="shadow border-0" bsPrefix='card h-100'>
            {
                readerStore.type === ReaderTypes.NEWSFEED ?
                <NewsfeedRW newsId={readerStore.id} close={readerStore.close} searchStr={readerStore.searchStr} /> :
                readerStore.type === ReaderTypes.NEWSFEED_FLASH ?
                <NewsfeedFlashRW newsId={readerStore.id} close={readerStore.close} searchStr={readerStore.searchStr}/> :
                readerStore.type === ReaderTypes.GRAPH ?
                <div>graph placeholder</div> :
                readerStore.type === ReaderTypes.STOCKFEED ?
                <div>stockfeed placeholder</div> :
                null
            }
          </Card>
      </div>
      : null
      }
      </>
    );
}
