import apiClient from "../http-common";
import { INewsDTO, INewsShort } from "./newsService";

export interface ISearchNewsDTO extends INewsDTO {
    searchText: string,
}

export interface ISearchResultNewsList {
    data:INewsShort[],
    hits: number,
}

/** Service for fetching data from the News controller. */
export default class SearchService {
    static readonly controller = '/search';

    static async GetNewsList(query:ISearchNewsDTO) {
        const response = await apiClient.post<ISearchResultNewsList>(`${this.controller}/newsList`, query);
        return response.data;
    }
}