import { da } from 'date-fns/locale';
import { GraphRange } from "../services/shareService";
import { format } from 'date-fns';

const localeStr = 'da-DK'

export function toLocalDateTimeStr (dateStr:string) {
    const now = new Date();

    const input = new Date(dateStr);

    // if different year or different month
    if (now.getFullYear() !== input.getFullYear() || now.getMonth() !== input.getMonth()) {
        return input.toLocaleString(localeStr, { year: 'numeric', month: 'short', day: 'numeric'})
    }
    // if same month
    else if (now.getDate() !== input.getDate()) {
        return  input.toLocaleString(localeStr, { day: 'numeric', month: 'short',  hour: '2-digit', minute: '2-digit' })
    }
    else {
        return input.toLocaleTimeString(localeStr, { hour: '2-digit', minute: '2-digit'})
    }
}

/** always returns hour/minute */
export function toLocalDateTimeFullStr (dateStr:string) {
    const now = new Date();

    const input = new Date(dateStr);

    // if different year or different month
    if (now.getFullYear() !== input.getFullYear() || now.getMonth() !== input.getMonth()) {
        return input.toLocaleString(localeStr, { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'})
    }
    // if same month
    else if (now.getDate() !== input.getDate()) {
        return  input.toLocaleString(localeStr, { day: 'numeric', month: 'short',  hour: '2-digit', minute: '2-digit' })
    }
    else {
        return input.toLocaleTimeString(localeStr, { hour: '2-digit', minute: '2-digit'})
    }
}

export function toLocalDateTimeStrRange (dateStr:string, range:GraphRange) {
    const input = new Date(dateStr);
    
    if (range == GraphRange.DAY_1 || range == GraphRange.DAY_5) {
        return input.toLocaleString(localeStr, { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'})
    }
    return  input.toLocaleString(localeStr, { day: 'numeric', month: 'short', year: 'numeric' })
}

export function graphTooltipDateStr (dateStr:string, range:GraphRange) {
    const input = new Date(dateStr);
    switch (range) {
        case GraphRange.DAY_1:
        case GraphRange.DAY_5:  
            return input.toLocaleTimeString(localeStr, { hour: '2-digit', minute: '2-digit'})
            
        default:
            return input.toLocaleString(localeStr, { year: 'numeric', month: 'short', day: 'numeric'})
    }
}

export function axisBottomDateFormatter (date: Date, minDate: Date, maxDate: Date) {
    const diff = daysBetween(minDate, maxDate);
    if (diff <= 2) {
        return date.toLocaleTimeString(localeStr, { hour: '2-digit', minute: '2-digit'})
    }
    else if (diff <= 8) {
        return date.toLocaleDateString(localeStr, { weekday: 'short', hour: '2-digit', minute: '2-digit' })
    }
    else if (diff <= 185) {
        return date.toLocaleDateString(localeStr, { year: 'numeric', month: 'short' })
    }
    else if (diff <= 400) {
        return date.toLocaleDateString(localeStr, { year: 'numeric', month: 'short' })
    }
    return date.toLocaleDateString(localeStr, { year: 'numeric', month: 'short' })
}

function treatAsUTC(date:Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
}

const millisecondsPerDay = 24 * 60 * 60 * 1000;
function daysBetween(startDate:Date, endDate:Date) {
    
    return (treatAsUTC(endDate).getTime() - treatAsUTC(startDate).getTime()) / millisecondsPerDay;
}


export function toCapitalizedMonth (date:Date) {
    const string = format(date, 'LLLL', { locale: da});
    return string.charAt(0).toUpperCase() +string.slice(1)
}

export function calendarDateFormat (date:Date) {
    if (date.getHours() == 0 && date.getMinutes() == 0)
    return date.toLocaleString(localeStr, { year: 'numeric', month: 'short', day: 'numeric'})
    return date.toLocaleString(localeStr, { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'})
}