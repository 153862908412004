import React, { useEffect } from "react";
import { useQueries, QueryCache, useQueryClient } from "@tanstack/react-query";
import { ItemProps } from '../layout/ColumnItem'
import { ItemTemplate } from "./ItemTemplate";
import { Button } from "react-bootstrap";
import { GraphSettings, ItemTypes } from "../../types/componentSettings";
import StockChart from "../shared/StockChart";
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import ShareService, { GraphRange, GraphData, ICompany } from "../../services/shareService";
import { useLayoutStore } from "../../stores/layout";
import { useNotifyStore } from "../../stores/notify";
import { getChange, getPercentChange, getPriceChangeClass, parsePrice, shortenPrice } from "../../helpers/shareHelper";
import { graphColors, graphRangeButtons, useStockWindowStore } from "../../stores/stockwindow";
import { useSignals } from "@preact/signals-react/runtime";
export interface GraphQueryKey {
    isin: string,
    graphRange: GraphRange,
}

export const Graph = (props:ItemProps) => {
    useSignals();
    const settings:GraphSettings = props.info.itemSettings;
    const openEditModal = useLayoutStore(state => state.modalShow)
    const updateSettings = useLayoutStore(state => state.updateColumnItemSettings)
    const notifyCreate = useNotifyStore(state => state.create)
    const swLastUpdate = useStockWindowStore(state => state.lastUpdate);
    const getButtonVariant = (gr:GraphRange) => {
        return (gr === settings.graphRange) ? 'primary' : 'outline-dark'
    }

    const handleClickGraphRangeButton = (gr:GraphRange) => {
        const newSettings:GraphSettings = { ...settings, graphRange: gr}
        updateSettings(props.columnId, props.index, newSettings);
    }

    const fetchPost = async (isin:string):Promise<GraphData> => {
        try {
            return await ShareService.GetGraphData(isin, settings.graphRange) 
        } catch (error:any) {
            notifyCreate({
                message: error?.message,
                bg: 'danger',
            });
            throw error;
        }
    }
    const results = useQueries<GraphData[]>({
        queries: settings.graphs.map(g => {
            const graphQueryKey:GraphQueryKey = { isin: g.isin, graphRange: settings.graphRange }
            return {
                queryKey: ['graphData', graphQueryKey],
                queryFn: async () => await fetchPost(g.isin),
                staleTime: 5 * 60_000
            }}),
    });
    
    const series = results.filter(x => (x?.data as GraphData|undefined)?.data?.length).map(x => (x.data as GraphData))
    const isLoading = results.some(result => result.isLoading)

    const queryClient = useQueryClient()
    /** use cache created by Stockfeed component such that prices of companies match in Graph component
     * if there is no Stockfeed component, there will be no cache - but there will also be no mismatch
     * since there is only one source of truth (this component)
     * note: only used for GraphRange.DAY_1, since Stockfeed only shows values for that range
     */
    const allCachedShares = queryClient.getQueriesData<ICompany[]>(['getSimpelShare']);
    const filteredCachedShares = allCachedShares.flatMap(x => x[1])
                                    .filter(x => settings.graphs.some(g => g.isin == x?.isin));


    /** tries to find a cached share for the provided isin string, and returns preformatted data.
     * if it cannot find it, it uses the graphs data instead.
     */
    const findCachedShare = (isin:string, gd:GraphData) => {
        if (settings.graphRange == GraphRange.DAY_1) {
            const cachedShare = filteredCachedShares.find(x => x?.isin == isin)?.shareprice;
            if (cachedShare) {
                return {
                    price: parsePrice(cachedShare.price),
                    class: getPriceChangeClass(cachedShare.change),
                    change: parsePrice(cachedShare.change),
                    changePct: cachedShare.changePct.toFixed(2).replace('.', ',')
                }
            }
        }
        return {
            price: parsePrice(gd.data[gd.data.length - 1]?.price),
            class: getPriceChangeClass(gd.data[gd.data.length - 1]?.price - gd.historicPrice.price),
            change: getChange(gd.historicPrice.price, gd.data[gd.data.length - 1]?.price),
            changePct: getPercentChange(gd.historicPrice?.price, gd.data[gd.data.length - 1]?.price)
        }
    }

    const headerPrices = React.useMemo(() => {
        const res = [];
        for (let i = 0; i < series.length; i++) {
            const gd = series[i];
            res.push(findCachedShare(gd.isin, gd));
        }
        return res;
    }, [swLastUpdate, filteredCachedShares, series, settings.graphRange]);

    return (
        <ItemTemplate { ...props } title={"Kursgraf"} alwaysShowTitle={false} isLoading={!series || isLoading}>
            {/* Header */}
            <div className="w-100 d-flex-inline justify-content-between">
                {
                    series.length ? series.map((g, i) => {
                        return (
                        <div className="d-flex align-items-center row px-1" key={i} style={{fontSize: 12}}>
                            <span className="col-5 text-truncate">
                            <i className="bi bi-circle-fill me-2" style={{color: graphColors[i]}}></i>
                                {g.name}
                            </span>
                            <span className="col-3 text-end fw-bold">{headerPrices[i]?.price}</span>
                            <span className={`col-4 text-end fw-bold ${headerPrices[i]?.class}`}>
                                {`${headerPrices[i]?.change} (${headerPrices[i]?.changePct}%)`}
                            </span>
                            
                        </div>
                    )}) : null
                }
            </div>
            {/* Body */}
            <div className="bg-white w-100">
                {(series.length && !isLoading) ?
                    (<ParentSize className="graph-container" debounceTime={10}>
                        {({ width: visWidth, height: visHeight }) => (
                            <StockChart
                                width={visWidth}
                                height={visHeight}
                                series={series}
                                colors={graphColors}
                                showBottomChart={false}
                                graphRange={settings.graphRange} />
                        )}
                    </ParentSize>) :
                    ( !isLoading ?
                        <div className="d-flex justify-content-center h-100 align-items-center">Ingen graf data tilgængelig i valgte periode</div>
                    : null)
                }
            </div>
            <div className="bg-white pb-2">
                <div className="d-flex justify-content-center">
                { graphRangeButtons.map((btn) => {
                    return (
                        <Button
                            size="sm"
                            key={btn.value}
                            onClick={() => handleClickGraphRangeButton(btn.value)}
                            className="rounded mx-1 d-flex justify-content-center"
                            style={{width: 25 + btn.text.length * 5}}
                            variant={getButtonVariant(btn.value)}>
                            <div>
                                { btn.text }
                            </div>
                        </Button>
                    )
                }) }
                </div>
                <div className="d-flex text-muted px-2 justify-content-between" style={{fontSize: 11, marginTop: 3}}>
                    <span>
                        Forsinket 15 min.
                    </span>
                    <span className="text-primary">
                        <u style={{cursor: 'pointer'}}
                            onClick={() => openEditModal(props.columnId, props.index)}>
                            Vælg data
                        </u>
                    </span>
                </div>
            </div>
        </ItemTemplate>
    )
}