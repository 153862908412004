import { create } from 'zustand'
import { ItemTypes } from '../types/componentSettings'

export enum ReaderTypes {
  NEWSFEED,
  NEWSFEED_FLASH,
  GRAPH,
  STOCKFEED
}

export interface ReaderState {
  visible: boolean,
  searchStr: string,
  type: ReaderTypes,
  id: string,
  close: () => void,
  show: (type:ReaderTypes, id:string, searchStr?:string) => void
}

export const useReaderStore = create<ReaderState>()((set) => ({
    id: '',
    searchStr: '',
    visible: false,
    type: ReaderTypes.NEWSFEED,
    close: () => set((state) => ({
        ...state,
        visible: false
      })),
    show: (type:ReaderTypes, id:string, searchStr?: string) => set((state) => ({
      ...state,
      visible: true,
      searchStr,
      type,
      id,
    })),
}))