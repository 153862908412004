import { useQueryClient } from "@tanstack/react-query";
import SignalRConnector, { invalidateCacheEvent } from '../signalr-connection';
import { useEffect } from "react";

/** hook to auto reconnect when websocket needs to reconnect, on focus events, and clear cache on freeze events
 * https://developer.chrome.com/docs/web-platform/page-lifecycle-api#events
*/
export function useInactivityHandler() {
    const queryClient = useQueryClient();
    
    // clear cache on reload event - happens when signalR connection has been dead for a while
    const { reconnectIfStale, setStale } = SignalRConnector();

    async function onFocusHandler () {
      console.log('onFocusHandler called')
      await reconnectIfStale();
    }

    function onFreezeHandler () {
      console.log('onFreezeHandler handler called')
      setStale();
    }

    function onResumeHandler () {
      console.log('onResumeHandler handler called')
      console.log(new Date());
      // queryClient.resetQueries();
      // console.log('resetQueries called at ' + new Date().toDateString())
    }

    async function onVisibilityChangedHandler() {
      console.log('visibilitychange handler called ' + document.hidden)
      if (document.hidden) {
        setStale();
      }
      else {
        await reconnectIfStale();
      }
    }

    function onInvalidateCacheHandler(staleDate:Date) {
      console.log(staleDate);
      queryClient.resetQueries();
    }

    useEffect(() => {
      document.addEventListener("resume", onResumeHandler);
      document.addEventListener("freeze", onFreezeHandler);
      document.addEventListener("visibilitychange", onVisibilityChangedHandler);
      window.addEventListener('focus', onFocusHandler);
      invalidateCacheEvent.addListener(onInvalidateCacheHandler);
      return () => {
        document.removeEventListener("resume", onResumeHandler);
        document.removeEventListener("freeze", onFreezeHandler);
        document.removeEventListener("visibilitychange", onVisibilityChangedHandler);
        window.removeEventListener('focus', onFocusHandler);
        invalidateCacheEvent.removeListener(onInvalidateCacheHandler);
      }
    }, []);
}