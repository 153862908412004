import apiClient from "../http-common";


export interface MyCompanyDTO {
    id: number;
    companyId: number;
    modtagSms: boolean;
    modtagMail: boolean;
    smsKursChng: number;
    smsKurs: boolean;
    smsNyhed: boolean;
    smsSelskab: boolean;
    smsFlash: boolean;
    mailKursChng: number;
    mailKurs: boolean;
    mailNyhed: boolean;
    mailSelskab: boolean;
    mailFlash: boolean;
    kursSendt: boolean;
    isin: string;
    companyName: string;
}
/** Service for fetching data from the MyCompanies controller. */
export default class MyCompaniesService {
    static readonly controller = '/myCompanies';

    static async GetList() {
        const response = await apiClient.get<MyCompanyDTO[]>(`${this.controller}`);
        return response.data;
    }

    static async Delete(id:number) {
        const response = await apiClient.delete<boolean>(`${this.controller}?id=${id}`);
        return response.data;
    }
    static async Create(companyId:number) {
        const response = await apiClient.post<MyCompanyDTO>(`${this.controller}/Create?companyId=${companyId}`);
        return response.data;
    }
    static async Update(myCompany:MyCompanyDTO) {
        const response = await apiClient.post<MyCompanyDTO>(`${this.controller}/Update`, myCompany);
        return response.data;
    }
}