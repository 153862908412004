
import { Nav, Container, Navbar, Button, Form, DropdownButton, Dropdown, Overlay, Tooltip } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useDNDStore } from '../../stores/dragndrop';
import logo from '../../assets/logo_name.svg';
import logo_white from '../../assets/logo_name_white.svg';
import { useAuth } from 'bff-auth-lib';
import { SelectableTheme, useThemeStore } from '../../stores/theme';
import { SettingsModal } from './../settingsModal/SettingsModal';
import { SaveLayoutModal } from '../SaveLayoutModal';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { useLayoutStore } from '../../stores/layout';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import LayoutService, { Layout, LayoutShareLevel } from '../../services/layoutService';

type Props = {
  showSettingsModal: () => void
}
export const NavMenu = (props:Props) => {
  useSignals();
  const queryClient = useQueryClient();
  const dndStore = useDNDStore();
  const themeStore = useThemeStore();
  const authContext = useAuth();
  const layoutStore = useLayoutStore();

  const unlockBtnRef = useRef(null);
  const [tooltip, setTooltip] = useState(false);
  useEffect(() => {
    setTooltip(dndStore.showTooltip);
  }, [dndStore.showTooltip])

  const layoutModalVisible = useSignal<boolean>(false);
  const showLayoutMOdal = () => { layoutModalVisible.value = true }
  const hideLayoutModal = () => { layoutModalVisible.value = false }

  const query = useQuery<Layout[]>({
    queryKey: ['layoutList', LayoutShareLevel.GLOBAL],
    queryFn: async () => await LayoutService.GetList(LayoutShareLevel.GLOBAL),
    staleTime: 5 * 60_000,
    enabled: layoutStore.layoutId ? true : false,
})

  return (
    <Navbar bg="d-fixed bg-light border-bottom border-gray" style={{ height: "45px" }}>
      <Container className="mx-0 mw-100">
        <Navbar.Brand className="">
          <img
            src={themeStore.theme === 'dark' ? logo_white : logo}
            height="25"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Nav className="">
          {
            dndStore.unlocked && <Button
            size="sm"
            className="me-1"
            variant='success'
            onClick={showLayoutMOdal}
            disabled={query.data?.find(x => x.id == layoutStore.layoutId) ? true : false}
          >
            Gem layout
          </Button>
          }
          <Button
            ref={unlockBtnRef}
            size="sm"
            className="me-1"
            variant={dndStore.unlocked ? 'dark' : 'outline-dark'}
            onClick={() => dndStore.updateUnlocked(!dndStore.unlocked)}
          >
            Layout {dndStore.unlocked ? <i className="bi bi-unlock"></i> : <i className="bi bi-lock"></i>}
          </Button>
          <Overlay target={unlockBtnRef.current} show={tooltip} placement="bottom" offset={[0, 10]}>
              {(props) =>
              <Tooltip {...props} color={'secondary'}>
                  Opsæt dit eget layout med nyheder, kurser og grafer
              </Tooltip>}
          </Overlay>
          <Button
            size="sm"
            className="me-1"
            variant='outline-dark'
            onClick={props.showSettingsModal}
            >
              {authContext?.user?.userProfile?.usrRealName || 'Profile'}
              <i className="bi bi-person ms-1" />
          </Button>
        </Nav>
      </Container>
      <SaveLayoutModal show={layoutModalVisible.value} close={hideLayoutModal} />
    </Navbar>
  )
}