import { GetAllEnumValues } from "../helpers/miscHelper";
import apiClient from "../http-common";

export interface ICalendarDTO {
    myStocks: boolean,
    filter: CalendarType[],
    fromDate: Date,
    limit: number
    skip: number,
}

export interface INewsShort {
    id: string,

    formatId: number,
    sectionId: number,

    formatName: string,
    sectionName: string,
    finansHeadline: string,
    journalistHeadline?: string,
    dateInserted: string,

    /** property added by websocket insert to determine if element should be animated */
    new?: boolean,
}

export enum CalendarType {
    ShareholderMeeting = 1101,
    MacroEconomy = 1143,
    FinancialResultsCompaniesAbroad = 1112,
    Others = 1100,
    FinancialResultsPeers = 1108,
    FinancialResultsQ1 = 1106,
    FinancialResultsQ2 = 1109,
    FinancialResultsQ3 = 1110,
    FinancialResultsQ4 = 1111,
    Divided = 1144
}

export const CalendarTypeList:CalendarType[] = GetAllEnumValues(CalendarType);

export const calendarTypeOptions = [
    { value: CalendarType.FinancialResultsPeers, label: "Regnskaber - Peers"},
    { value: CalendarType.FinancialResultsCompaniesAbroad, label: "Regnskaber - Udland"},
    { value: CalendarType.Divided, label: "Udbytte" },
    { value: CalendarType.ShareholderMeeting, label: "Generalforsamling" },
    { value: CalendarType.MacroEconomy, label: "Makro" },
    { value: CalendarType.Others, label: "Øvrigt" },
];

export const calendarNameMap = new Map<CalendarType, string>([
    [ CalendarType.ShareholderMeeting, "Generalforsamling" ],
    [ CalendarType.MacroEconomy, "Makro" ],
    [ CalendarType.Others, "Øvrigt" ],
    [ CalendarType.Divided, "Udbytte" ],
    [ CalendarType.FinancialResultsCompaniesAbroad, "Regnskaber - Udland" ],
    [ CalendarType.FinancialResultsPeers, "Regnskaber - Peers" ],
    [ CalendarType.FinancialResultsQ1, "Regnskaber - DK" ],
    [ CalendarType.FinancialResultsQ2, "Regnskaber - DK" ],
    [ CalendarType.FinancialResultsQ3, "Regnskaber - DK" ],
    [ CalendarType.FinancialResultsQ4, "Regnskaber - DK" ],
]);

export const calendarTypeFinancialDenmarkArray = [
    CalendarType.FinancialResultsQ1,
    CalendarType.FinancialResultsQ2,
    CalendarType.FinancialResultsQ3,
    CalendarType.FinancialResultsQ4,
]
export const financialResultsCheckValue = CalendarType.FinancialResultsQ1;

export interface IFinanceCalendar {
    id: number;
    eventType: CalendarType,
    eventDate: string,
    isin?: string,
    companyName?: string,
    text?: string,
    
    marketwireCoverage: boolean,
    marketwireInterview: boolean,

    marketwireCoverageText?:string,
    marketwireInterviewText?:string,
    infoText?:string,

    
    subjectId?: number,
    subjectName?: string,
}
export const pageSize = 15;

/** Service for fetching data from the News controller. */
export default class CalendarService {
    static readonly controller = '/calendar';

    static async GetList(dto:ICalendarDTO) {
        const response = await apiClient.post<IFinanceCalendar[]>(`${this.controller}/GetListWithFilter`, dto);
        return response.data;
    }

}