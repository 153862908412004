import React, { useState } from "react";
import { ItemProps } from '../layout/ColumnItem';
import { ItemTemplate } from "./ItemTemplate";
import { Badge, Button, InputGroup, ListGroup } from "react-bootstrap";
import { StockfeedSettings } from "../../types/componentSettings";
import { ReaderTypes, useReaderStore } from "../../stores/reader";
import { useNotifyStore } from "../../stores/notify";
import { useQuery } from "@tanstack/react-query";
import ShareService, { ICompany, InstrumentType, MarketIndex } from "../../services/shareService";
import { useLayoutStore } from "../../stores/layout";
import { getSharePriceChangeClass, parsePrice } from "../../helpers/shareHelper";
import { useStockWindowStore }  from '../../stores/stockwindow'
import { SortButton, SortState } from "../shared/SortButton";
import { Search } from "../shared/Search";
import { MyStocksEmptyText } from "../shared/MyStocksEmptyText";
import { compareAsc, compareDesc } from "../../helpers/miscHelper";

export const Stockfeed = (props:ItemProps) => {
    const showStockWindow = useStockWindowStore(state => state.show);
    const settings:StockfeedSettings = props.info.itemSettings;
    const updateSettings = useLayoutStore(state => state.updateColumnItemSettings)
    const notifyCreate = useNotifyStore(state => state.create)
    const query = useQuery<ICompany[]>({
        queryKey: ['getSimpelShare', settings.marketIndex],
        queryFn: async () => { 
            try {
                return await ShareService.GetListSimpel(settings.marketIndex) 
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        staleTime: 1 * 60_000,
    })
    const data = query.data;

    const setMarketIndex = (marketIndex:MarketIndex) => {
        const newSettings = {
            ...settings,
            marketIndex: marketIndex
        }
        updateSettings(props.columnId, props.index, newSettings);
    }

    function showStockWindowIfIsin(isin: string|null, marketIndex:MarketIndex) {
        if (isin?.length) {
            showStockWindow(isin, marketIndex);
        }  
        else {
            // no isin?
            console.log(isin);
        }
    }

    const setSettingsSorting = (sort:'name'|'change'|'changePct', order:SortState) => {
        const newSettings:StockfeedSettings = { ...settings, sort: sort}
        if (newSettings.sort != settings.sort) {
            newSettings.order = 'desc'
        }
        else {
            newSettings.order = order;
        }
        updateSettings(props.columnId, props.index, newSettings);
    }

    const [searchText, setSearchText] = useState<string>('');

    
    const lowercaseSearchText = searchText.toLocaleLowerCase();
    const sorted = searchText ? data?.slice().filter(x => x.name.toLocaleLowerCase().includes(lowercaseSearchText)) : data?.slice();

    if (sorted?.length) {
        const desc:boolean = settings.order != 'asc';

        if (settings.sort == 'change' && settings.order != 'off') {
            sorted.sort((a, b) => desc
                ? compareDesc(a.shareprice?.change || 0, b.shareprice?.change || 0)
                : compareAsc(a.shareprice?.change || 0, b.shareprice?.change || 0));
        }
        else if (settings.sort == 'changePct' && settings.order != 'off') {
            sorted.sort((a, b) => desc
                ? compareDesc(a.shareprice?.changePct || 0, b.shareprice?.changePct || 0)
                : compareAsc(a.shareprice?.changePct || 0, b.shareprice?.changePct || 0));
        }
        else {
            sorted.sort((a, b) => desc
                ? compareDesc(a.name.toLocaleLowerCase(), b.name.toLocaleLowerCase())
                : compareAsc(a.name.toLocaleLowerCase(), b.name.toLocaleLowerCase()));
        }

        // regardless of current sorting, we sort for .typeOfInstrument and then for the "Index" enum, we sort them alphabetically
        sorted.sort((a, b) => compareAsc(a.typeOfInstrument, b.typeOfInstrument));
        sorted.sort((a, b) => (a.typeOfInstrument == InstrumentType.Index && b.typeOfInstrument == InstrumentType.Index)
            ? compareDesc(a.name.toLocaleLowerCase(), b.name.toLocaleLowerCase())
            : 0);
    }
    const noMyStocksAndEmptyList = settings.marketIndex == MarketIndex.MY_SHARES && sorted?.length == 0;
    return (
        <ItemTemplate { ...props } alwaysShowTitle={true} title={"Aktiefeed"} isLoading={query.isLoading}>
            {/* Header */}
            <>
                <Button className="ms-1" style={{whiteSpace: 'nowrap'}}
                    onClick={() => setMarketIndex(MarketIndex.MY_SHARES)}
                    variant={settings.marketIndex === MarketIndex.MY_SHARES ? 'primary rounded' : 'outline-secondary rounded'}>
                    <i className="bi bi-person" />
                </Button>
                <Button
                    className="ms-1"
                    onClick={() => setMarketIndex(MarketIndex.OMXCAP25)}
                    variant={settings.marketIndex === MarketIndex.OMXCAP25 ? 'primary rounded' : 'outline-secondary rounded'}>
                    C25
                </Button>
                <Button className="mx-1" style={{whiteSpace: 'nowrap'}}
                    onClick={() => setMarketIndex(MarketIndex.OMXC)}
                    variant={settings.marketIndex === MarketIndex.OMXC ? 'primary rounded' : 'outline-secondary rounded'}>
                    OMX C
                </Button>
                <Search searchText={searchText} setSearchText={setSearchText} />
            </>
            {/* Body */}
            <div className="bg-white px-1 w-100 rounded-bottom">
                <div className="d-flex justify-content-between text-muted px-2" 
                    style={{minWidth: 'max-content', fontSize: 11, fontVariant: '', top: 0, position: 'sticky'}}>
                    <span>
                        <SortButton label="Navn" state={settings.sort == 'name' ? settings.order : 'off'} setState={(val) => setSettingsSorting('name', val)}  />
                    </span>
                    <span style={{minWidth: 'max-content'}}>
                        <span style={{marginRight: 12}}>
                            Seneste
                        </span>
                        <span style={{minWidth: 75, textAlign: 'right', paddingInline: '0.65em'}}>
                            <SortButton label="Ændring i %" state={settings.sort == 'changePct' ? settings.order : 'off'} setState={(val) => setSettingsSorting('changePct', val)} />
                        </span>
                        <span className="d-none-mobile" style={{minWidth: 70, textAlign: 'right', paddingInline: '0.65em'}}> 
                            <SortButton label="Ændring" state={settings.sort == 'change' ? settings.order : 'off'} setState={(val) => setSettingsSorting('change', val)}  />
                        </span>
                    </span>
                </div>
                <div style={{height: 'calc(100% - 16.5px)'}} className="overflow-auto">
                    <ListGroup variant="flush" as="ol" className="h-100">
                    {
                        noMyStocksAndEmptyList ? <MyStocksEmptyText /> :
                        sorted ?
                        sorted.map((item, index) => {
                            return (
                                <li key={index}
                                    className="list-group-item list-group-item-action d-flex justify-content-between p-2 fs-7"
                                    onClick={() => showStockWindowIfIsin(item.isin, settings.marketIndex)}
                                    style={{cursor:'pointer'}}
                                >
                                    <span>
                                        {item.name}
                                    </span>
                                    <span className="ms-1 d-flex fs-7" style={{minWidth: 'max-content'}}>
                                        <span className="d-flex justify-content-end">
                                            <Badge pill bg="transparent" className=" text-dark fs-7 my-auto">
                                                { parsePrice(item.shareprice?.price) }
                                            </Badge>
                                        </span>
                                        <span style={{minWidth: 75, textAlign: 'right'}} className="d-flex justify-content-end">
                                            <Badge pill bg="none" className={getSharePriceChangeClass(item) + ' fs-7 my-auto'}>
                                                { parsePrice(item.shareprice?.changePct) + '%' }
                                            </Badge>
                                        </span>
                                        <span style={{minWidth: 70, textAlign: 'right'}} className="d-flex justify-content-end d-none-mobile">
                                            <Badge pill bg="none" className={getSharePriceChangeClass(item) + ' fs-7 my-auto'}>
                                                {parsePrice(item.shareprice?.change)}
                                            </Badge>
                                        </span>
                                    </span>
                                </li>
                            )
                        })
                        : null
                    }
                    </ListGroup>
                </div>
                
            </div>
        </ItemTemplate>
    )
}

